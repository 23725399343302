import React, { FC } from "react";
import Icon from "./Icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { MEDIA_MAX_M } from "../constants/styles";

const Container = styled.ul`
  max-width: 650px;
  list-style: none;
  margin: 40px auto 80px;
  @media ${MEDIA_MAX_M} {
    margin: 10px auto 40px;
  }
  padding: 0 20px;
`;

const Item = styled.li`
  font-size: 30px;
  @media ${MEDIA_MAX_M} {
    font-size: 20px;
  }
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const IconWrap = styled.div`
  width: 80px;
  @media ${MEDIA_MAX_M} {
    width: 50px;
  }
  flex-shrink: 0;
  text-align: right;
`;
const SxIcon = styled(Icon)`
  padding-right: 20px;
  text-align: center;
  font-size: 1.5em;
`;

interface PropsT {
  items: {
    icon: IconProp;
    text: string;
  }[];
}

const CheckList: FC<PropsT> = (props) => (
  <Container>
    {props.items.map((item) => (
      <Item key={item.text}>
        <IconWrap>
          <SxIcon icon={item.icon} />
        </IconWrap>
        <span>{item.text}</span>
      </Item>
    ))}
  </Container>
);

export default CheckList;
