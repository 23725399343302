import React, { ReactNode } from "react";
import T from "i18n-react";
import LoginSignupToggle from "./LoginSignupToggle";
import ErrorMessage from "./ErrorMessage";
import LoadingSpinner from "./LoadingSpinner";

import { KeyEventType } from "../constants/types";

interface PropsT {
  type: string;
  submitLabel: ReactNode;
  error: ReactNode | null;
  loading?: boolean | null;
  passwordSubtext?: Object;
  onSubmit: Function;
}

class LoginSignupForm extends React.Component {
  props: PropsT;

  _onSubmit(e: KeyEventType) {
    e.preventDefault();

    this.props.onSubmit(
      (this as any).email.value,
      (this as any).password.value
    );
  }

  render() {
    const { type, error, loading, submitLabel, passwordSubtext } = this.props;

    return (
      <form className="login-signup-form" onSubmit={this._onSubmit.bind(this)}>
        {<LoginSignupToggle active={type} />}
        {error && <ErrorMessage message={error} />}
        {loading && <LoadingSpinner />}
        <label className="login-signup-form__input-group">
          <span className="login-signup-form__label">
            {T.translate("Common.Email")}
          </span>
          <input
            className="login-signup-form__input"
            ref={(c) => {
              (this as any).email = c;
            }}
            type="email"
            required={true}
          />
        </label>
        <label className="login-signup-form__input-group">
          <span className="login-signup-form__label">
            {T.translate("Common.Password")}
          </span>
          <input
            className="login-signup-form__input"
            ref={(c) => {
              (this as any).password = c;
            }}
            type="password"
            required={true}
          />
        </label>
        {passwordSubtext}
        <button
          className="login-signup-form__submit btn btn--small btn--green"
          type="submit"
        >
          {submitLabel}
        </button>
      </form>
    );
  }
}

export default LoginSignupForm;
