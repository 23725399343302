import React, { FC } from "react";
import { useDispatch } from "react-redux";
import actions from "../../actions";

import ModalContainer from "./ModalContainer";

const HelpModal: FC = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());

  return (
    <ModalContainer includesCloseIcon={true} onCloseModal={closeModal}>
      <div className="modal--padded">
        <h1>How it works</h1>
        <p>
          Click the title or description of a card to edit. Hit enter to save.
        </p>
        <p>
          Hover over a card and click the plus button to add a first sub-card.
        </p>
        <p>
          {"The bottom of a card shows its sub-cards."}
          {"They turn green when you complete them."}
          {
            'A card is automatically marked "completed" once all its sub-cards are completed.'
          }
        </p>
        <h1>Keyboard commands</h1>
        <div className="key-command">
          <div className="description">
            <div className="description-item">
              <i className="keyboard-icon">ESC</i>
            </div>
            <p className="description-item">go out of a card</p>
          </div>
        </div>
        <div className="key-command">
          <div className="description">
            <div className="description-item">
              <i className="keyboard-icon">1-9</i>
            </div>
            <p className="description-item">go into a card</p>
          </div>
        </div>
        <div className="key-command">
          <div className="description">
            <div className="description-item">
              <i className="keyboard-icon">A</i>
            </div>
            <p className="description-item">add a card</p>
          </div>
        </div>
        <div className="key-command">
          <div className="description">
            <div className="description-item">
              <i className="keyboard-icon">U</i>
            </div>
            <p className="description-item">undo the last action</p>
          </div>
        </div>
        <div className="key-command">
          <div className="description">
            <div className="description-item">
              <i className="keyboard-icon">⌘/CTRL</i>
            </div>
            <p className="description-item">
              hold and drag one card into another
            </p>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default HelpModal;
