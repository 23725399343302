import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <Link className="footer__item" to="/imprint">
      Imprint
    </Link>
    <a
      className="footer__item"
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/kairollmann"
    >
      @kairollmann
    </a>
  </footer>
);

export default Footer;
