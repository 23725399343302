import React, { ReactNode, FC } from "react";
import Icon from "./Icon";

interface PropsT {
  message: ReactNode;
}

const ErrorMessage: FC<PropsT> = (props) => (
  <p className="error-message">
    <Icon icon="times" /> {props.message}
  </p>
);

export default ErrorMessage;
