import React, { FC } from "react";
import classnames from "classnames";
import IconButton from "./IconButton";

interface PropsT {
  className?: string;
  onAddNode: () => void;
  text?: string;
}

const AddNodeButton: FC<PropsT> = ({ className, onAddNode, text }) => {
  return (
    <IconButton
      title="Add a card"
      onClick={onAddNode}
      className={classnames(
        "btn btn--transparent btn--tiny btn--square",
        className
      )}
      large={!text}
      iconClassName="plus"
      text={text}
    />
  );
};

export default AddNodeButton;
