import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { trackTreeStats } from "../../helpers/trackingHelper";
import { isAppRoute } from "../../helpers/routesHelper";
import { includes } from "../../helpers/commonHelper";

import { MOVE_INTO, MOVE_SAME_LEVEL } from "../../constants/childMoveModeTypes";

import {
  KEY_CODE_ZERO,
  ALLOWED_CMD_CTRL_KEY_CODES,
  KEY_BACKSPACE,
  KEY_ESC,
  KEY_A,
  KEY_U,
  KEY_Z,
} from "../../constants/keyCodes";

import { KeyEventType } from "../../constants/types";

import Node from "../Node";
import NodeLoading from "../NodeLoading";
import Header from "../Header";
import Footer from "../Footer";
import { DataStateType, CardType } from "../../reducers/dataReducer";

type PropsType = {
  location: any;
  activeNode: DataStateType | CardType | null;
  isRootNode: boolean;
  isLoadingGet: boolean;
  isLoadingSave: boolean;
  isLoggedIn: boolean;
  hasError: boolean;
  getData: Function;
  undo: Function;
  addNode: Function;
  addNodeBefore: Function;
  moveChildInto: Function;
  navigateInto: Function;
  navigateUpTimes: Function;
  initTreePosition: Function;
  setChildMoveMode: Function;
};

class App extends React.Component {
  constructor(props: PropsType) {
    super(props);

    (this as any).boundKeyUpHandler = this._onKeyUp.bind(this);
    (this as any).boundKeyDownHandler = this._onKeyDown.bind(this);
    (this as any).shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(
      this
    );
  }

  componentWillMount() {
    if (this.props.isLoggedIn) this.props.getData();
    else this.props.initTreePosition();
  }

  componentDidMount() {
    window.addEventListener("keydown", (this as any).boundKeyDownHandler);
    window.addEventListener("keyup", (this as any).boundKeyUpHandler);
  }

  componentWillReceiveProps(nextProps: PropsType) {
    if (
      !!nextProps.activeNode &&
      this.props.location.pathname !== nextProps.location.pathname &&
      isAppRoute(nextProps.location.pathname)
    )
      trackTreeStats(nextProps.activeNode);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", (this as any).boundKeyDownHandler);
    window.removeEventListener("keyup", (this as any).boundKeyUpHandler);
  }

  props: PropsType;

  _handleNumberPressedMaybe(keyCode: number) {
    if (!this.props.activeNode) return;

    const number = keyCode - KEY_CODE_ZERO;
    const childIndexExists =
      this.props.activeNode.children.length - number >= 0;

    if (number >= 1 && number <= 10 && childIndexExists) {
      const index = number - 1;

      this._onNavigateInto(index);
    }
  }

  _handleEscPressed() {
    this.props.navigateUpTimes(1);
  }

  _handleCmdUp() {
    this.props.setChildMoveMode(MOVE_SAME_LEVEL);
  }

  _handleCmdDown() {
    this.props.setChildMoveMode(MOVE_INTO);
  }

  _onKeyDown(e: KeyEventType) {
    if (
      (e.target as HTMLElement).tagName === "TEXTAREA" ||
      (e.target as HTMLElement).tagName === "INPUT"
    )
      return null;

    if (e.ctrlKey || e.metaKey)
      if (includes(ALLOWED_CMD_CTRL_KEY_CODES, e.keyCode))
        return this._handleCmdDown();
      // Someone pressed CMD or CTRL as a single key
      else if (e.keyCode === KEY_Z)
        // CMD/CTRL + Z
        return this.props.undo();

    switch (e.keyCode) {
      case KEY_BACKSPACE:
        e.preventDefault();
        return this._handleEscPressed();
      case KEY_ESC:
        return this._handleEscPressed();
      case KEY_A:
        return this.props.addNode();
      case KEY_U:
        return this.props.undo();
      default:
        return this._handleNumberPressedMaybe(e.keyCode);
    }
  }

  _onKeyUp(e: KeyEventType) {
    if ((e.target as HTMLElement).tagName === "INPUT") return;

    if (includes(ALLOWED_CMD_CTRL_KEY_CODES, e.keyCode)) this._handleCmdUp();
  }

  _onNavigateInto(index: number) {
    const { activeNode, navigateInto } = this.props;

    if (!activeNode || !activeNode.children[index].children) return;

    navigateInto(activeNode.children, index);
  }

  render() {
    const {
      location,
      activeNode,
      isRootNode,
      isLoadingGet,
      addNode,
      addNodeBefore,
      moveChildInto,
      navigateInto,
      undo,
    } = this.props;

    return (
      <div className="app" key={location.pathname}>
        {isRootNode && <Header key={0} location={location} />}
        <div className="app__content">
          {activeNode && !isLoadingGet ? (
            <Node
              node={activeNode}
              location={location}
              onNavigateInto={(index) =>
                navigateInto(activeNode.children, index)
              }
              onAddNode={addNode}
              onAddNodeBefore={addNodeBefore}
              onUndo={undo}
              onMoveChildInto={(sourceIndex, targetIndex) => {
                // Reset CMD - because it sometimes isn't reset.
                // this._handleCmdUp();
                moveChildInto(sourceIndex, targetIndex);
              }}
            />
          ) : (
            <NodeLoading />
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
