import { StateType } from ".";

// So we disable undo for non-user interaction actions
function shouldSaveUndo(action: { type: string }) {
  const blacklist = [
    "@@INIT",
    "REDUX_STORAGE_SAVE",
    "REDUX_STORAGE_LOAD",
    "UNDO",
    "NONE",
    "GET_DATA_SUCCESS",
    "GET_DATA_ERROR",
    "GET_DATA_START",
    "PATCH_DATA_SUCCESS",
    "PATCH_DATA_ERROR",
    "PATCH_DATA_START",
    "SET_CHILD_MOVE_MODE",
    "TOGGLE_EDIT_TITLE",
    "TOGGLE_EDIT_DESCRIPTION",
  ];

  return (
    blacklist.indexOf(action.type) === -1 && !action.type.startsWith("API")
  );
}

// So we don't undo content of keys that store API results
function shouldUndoKey(key: string): boolean {
  const whitelist = ["data", "helpModalOpen", "treePosition"];

  return whitelist.indexOf(key) !== -1;
}

function combineReducers(reducers: any) {
  return (
    // @ts-ignore
    state: StateType & { history: StateType[] } = {},
    action: { type: string }
  ) => {
    if (action.type === "UNDO" && state.history.length > 0)
      // Load previous state and pop the history
      return {
        ...Object.keys(reducers).reduce((stateKeys, key) => {
          // @ts-ignore
          stateKeys[key] = shouldUndoKey(key)
            ? (state.history[0] as any)[key]
            : (state as any)[key];
          return stateKeys;
        }, {}),
        history: state.history.slice(1),
      };

    // Save a new undo unless the action is blacklisted
    const newHistory = shouldSaveUndo(action)
      ? [
          {
            ...Object.keys(reducers).reduce((stateKeys, key) => {
              // @ts-ignore
              stateKeys[key] = state[key];
              return stateKeys;
            }, {}),
          },
        ]
      : undefined;

    return {
      // Calculate the next state
      ...Object.keys(reducers).reduce((stateKeys, key) => {
        // @ts-ignore
        stateKeys[key] = reducers[key](state[key], action);
        return stateKeys;
      }, {}),
      history: [...(newHistory || []), ...(state.history || [])].slice(0, 10),
    };
  };
}

export default combineReducers;
