export const MAX_WIDTH = "1024px";
export const BORDER_RADIUS = "5px";
export const TRANSITION_TIME = ".2s";

export const FONT_SM = "14px";
export const FONT_MD = "18px";
export const FONT_LG = "24px";

export const SCREEN_M_MAX = 899;
export const SCREEN_M_MIN = 900;

export const SCREEN_S_MAX = 639;
export const SCREEN_S_MIN = 640;

export const MEDIA_MAX_M = `( max-width: ${SCREEN_M_MAX}px )`;
export const MEDIA_MIN_M = `( min-width: ${SCREEN_M_MIN}px )`;

export const MEDIA_MAX_S = `( max-width: ${SCREEN_S_MAX}px )`;
export const MEDIA_MIN_S = `( min-width: ${SCREEN_S_MIN}px )`;

export const COL_BG = "#efefef";
export const COL_BLACK = "#444";
export const COL_GRAY_LIGHT = "#ccc";
export const COL_GRAY = "#aaa";

export const COL_GREEN = "#166";
export const COL_GREEN_DARK = "#035252";

export const COL_ORANGE = "#f81";
export const COL_ORANGE_DARK = "#CE6600";

export const COL_RED = "#c33";
export const COL_RED_LIGHT = "#fdd";
