import React, { useRef, FC } from "react";
import HTML5Backend from "react-dnd-html5-backend";
import { createDndContext, DndProvider } from "react-dnd";
import { connect } from "react-redux";
import actions from "../actions";

import { getParentNodes } from "../selectors/parentNodesSelector";

import Child from "./Child";
import AddNodeButton from "./AddNodeButton";
import UndoButton from "./UndoButton";
import MediaQuery from "./MediaQuery";
import PathNavigation from "./PathNavigation";
import { StateType } from "../reducers";
import { CardType } from "../reducers/dataReducer";

const DndContext = createDndContext(HTML5Backend);

const getSize = (childrenLength: number): CardSize => {
  if (childrenLength > 12) return "S";
  if (childrenLength > 6) return "M";

  return "L";
};

export interface ParentNode {
  title: string;
  description: string;
}
export type CardSize = "S" | "M" | "L";

interface PropsT {
  node: CardType;
  parentNodes: ParentNode[];
  onNavigateInto: Function;
  onAddNode: () => void;
  onAddNodeBefore: () => void;
  onMoveChildInto: Function;
  onMoveChildUpTimes: Function;
  onUndo: () => void;
  onNavigateUpTimes: Function;
}

const Node: FC<PropsT> = (props) => {
  const size = getSize(props.node.children.length);

  const managerRef = useRef(DndContext);

  return (
    <DndProvider
      backend={HTML5Backend}
      manager={managerRef.current.dragDropManager}
    >
      <div className="node">
        {props.node.title && (
          <PathNavigation
            parentNodes={props.parentNodes}
            onNavigateUpTimes={props.onNavigateUpTimes}
            onMoveChildUpTimes={props.onMoveChildUpTimes}
          />
        )}
        <div className="node__top-btns">
          <MediaQuery query="xs-only">
            <div>
              <UndoButton
                className="node__top-btns__btn"
                onUndo={props.onUndo}
              />
              <AddNodeButton
                className="node__top-btns__btn"
                onAddNode={props.onAddNodeBefore}
                text="Add"
              />
            </div>
          </MediaQuery>
          <MediaQuery query="xs-bigger">
            <AddNodeButton onAddNode={props.onAddNode} />
          </MediaQuery>
        </div>
        <div className="children">
          {props.node.children.map((child, i) => (
            <Child
              key={child.id}
              index={i}
              size={size}
              node={child}
              onNavigateInto={props.onNavigateInto}
              onMoveChildInto={props.onMoveChildInto}
            />
          ))}
        </div>
        {props.node.children.length > 0 && (
          <MediaQuery query="xs-only">
            <AddNodeButton onAddNode={props.onAddNode} text="Add" />
          </MediaQuery>
        )}
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state: StateType) => ({
  treePosition: state.treePosition,
  parentNodes: getParentNodes(state.data, state.treePosition),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  const { pathname } = ownProps.location;

  return {
    onMoveChildUpTimes: (treePosition, sourceIndex, times) =>
      dispatch(actions.moveChildUpTimes(treePosition, sourceIndex, times)),
    onNavigateUpTimes: (times) =>
      dispatch(actions.navigateUpTimes(pathname, times)),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { treePosition } = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onMoveChildUpTimes: (sourceIndex, times) =>
      dispatchProps.onMoveChildUpTimes(treePosition, sourceIndex, times),
  };
};

const ConnectedNode = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Node);

export default ConnectedNode;
