import React from "react";

// const GIT_COMMIT = process.env.REACT_APP_GIT_COMMIT;
// const COMMITTED_AT = process.env.REACT_APP_COMMITTED_AT;
// const BUILT_AT = process.env.REACT_APP_BUILT_AT;

const Version = () => (
  <div>
    <p>Latest!</p>
  </div>
);
// {/* <p>Git commit: {GIT_COMMIT}</p>
// <p>Committed at: {COMMITTED_AT}</p>
// <p>Built at: {BUILT_AT}</p> */}

export default Version;
