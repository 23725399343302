import T from "i18n-react";

import {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
} from "../helpers/authHelper";
import { ReactNode } from "react";

export interface UserStateType {
  email: string | null;
  isLoggedIn: boolean;
  signUp: {
    loading: boolean;
    error: ReactNode | null;
  };
  login: {
    loading: boolean;
    error: ReactNode | null;
  };
  lastUpdated: number | null;
}

const initialState: UserStateType = {
  email: null,
  isLoggedIn: !!getAuthToken(),
  signUp: {
    loading: false,
    error: null,
  },
  login: {
    loading: false,
    error: null,
  },
  lastUpdated: null,
};

function loginSuccess(state: UserStateType, action: any) {
  const { token } = action.payload.data;

  if (token) setAuthToken(token);

  return {
    ...state,
    isLoggedIn: !!token,
    lastUpdated: action.payload.receivedAt,
    login: {
      error: null,
      loading: false,
    },
  };
}

// TODO: Get email in a better way

function user(state: UserStateType = initialState, action: any): UserStateType {
  switch (action.type) {
    case "SIGN_UP_START":
      return {
        ...state,
        signUp: {
          loading: true,
          error: null,
        },
      };
    case "SIGN_UP_SUCCESS":
      return {
        ...state,
        email: action.payload.data.email,
        signUp: {
          loading: false,
          error: null,
        },
      };
    case "SIGN_UP_ERROR":
      return {
        ...state,
        signUp: {
          loading: false,
          error: T.translate("ErrorMessages.SignUpFailed"),
        },
      };
    case "LOGIN_START":
      return {
        ...state,
        login: {
          loading: true,
          error: null,
        },
      };
    case "LOGIN_SUCCESS":
      return loginSuccess(state, action);
    case "LOGIN_ERROR":
      return {
        ...state,
        login: {
          loading: false,
          error: T.translate("ErrorMessages.LoginFailed"),
        },
      };
    case "LOGOUT":
      // Yes, side effect. Alternatively I could put it into componentDidUpdate
      // But that involes another check if isLoggedIn changed.
      removeAuthToken();

      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}

export default user;
