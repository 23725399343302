import React, { FC } from "react";
import T from "i18n-react";
import classnames from "classnames";
import IconButton from "./IconButton";

interface PropsT {
  className?: string;
  onUndo: () => void;
}

const UndoButton: FC<PropsT> = (props) => (
  <IconButton
    onClick={props.onUndo}
    className={classnames(
      "btn btn--transparent btn--tiny btn--square",
      props.className
    )}
    title="Undo last action"
    text={T.translate("Common.Undo")}
    iconClassName="undo"
  />
);

export default UndoButton;
