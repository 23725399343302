import fetch from "isomorphic-fetch";
import { getAuthToken } from "./helpers/authHelper";

import { API_URL } from "./constants/routes";

// Similar to:
// http://stackoverflow.com/questions/29473426/fetch-reject-promise-with-json-error-object
function fetchJson(url: string, request: any) {
  const requestWithHeaders = {
    ...request,
    body: JSON.stringify(request.body),
    headers: {
      ...request.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(url, requestWithHeaders).then(
    (response) => {
      if (response.status >= 200 && response.status < 300)
        return response.json();

      // Reject other status
      return response.json().then(Promise.reject.bind(Promise));
    },
    (error) => Promise.reject(error) // Network or connection failure
  );
}

function fetchJsonWithToken(url: string, request: any) {
  const requestWithToken = {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  return fetchJson(url, requestWithToken);
}

//
//  API CALLS
//
export function postAuthenticate(email: string, password: string) {
  return fetchJson(`${API_URL}/auth`, {
    method: "POST",
    body: {
      email,
      password,
    },
  });
}

export function postUsers(email: string, password: string) {
  return fetchJson(`${API_URL}/users`, {
    method: "POST",
    body: {
      email,
      password,
    },
  });
}

export function getData() {
  return fetchJsonWithToken(`${API_URL}/me/data`, {
    method: "GET",
  });
}

export function patchData(lastUpdatedAt: number, data: any) {
  return fetchJsonWithToken(`${API_URL}/me/data`, {
    method: "PATCH",
    body: {
      lastUpdatedAt,
      data,
    },
  });
}
