// SPECIAL ACTION TYPES
export const NONE = "NONE";
export const UNDO = "UNDO";

// CARD ACTION TYPES
export const ADD_NODE = "ADD_NODE";
export const ADD_NODE_BEFORE = "ADD_NODE_BEFORE";
export const REMOVE_NODE = "REMOVE_NODE";
export const ADD_CHILD = "ADD_CHILD";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const SET_COMPLETED = "SET_COMPLETED";
export const SET_MARKED = "SET_MARKED";
export const MOVE_CHILD = "MOVE_CHILD";
export const MOVE_CHILD_INTO = "MOVE_CHILD_INTO";
export const MOVE_CHILD_UP_TIMES = "MOVE_CHILD_UP_TIMES";
export const TOGGLE_EDIT_TITLE = "TOGGLE_EDIT_TITLE";
export const TOGGLE_EDIT_DESCRIPTION = "TOGGLE_EDIT_DESCRIPTION";

// DATA ACTION TYPES
export const GET_DATA_START = "GET_DATA_START";
export const GET_DATA_ERROR = "GET_DATA_ERROR";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const PATCH_DATA_START = "PATCH_DATA_START";
export const PATCH_DATA_ERROR = "PATCH_DATA_ERROR";
export const PATCH_DATA_SUCCESS = "PATCH_DATA_SUCCESS";

// LOGIN ACTION TYPES
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const LOGOUT = "LOGOUT";

// NAVIGATE ACTION TYPES
export const TREE_POSITION_UP = "TREE_POSITION_UP";
export const TREE_POSITION_INTO = "TREE_POSITION_INTO";
export const TREE_POSITION_INIT = "TREE_POSITION_INIT";

// MODAL ACTIONS
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// CHILD MOVE MODE ACTIONS
export const SET_CHILD_MOVE_MODE = "SET_CHILD_MOVE_MODE";
