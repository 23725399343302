import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";

import { StateType } from "../reducers";
import { DataStateType } from "../reducers/dataReducer";

function getSize() {
  const docEl = document.documentElement;
  const body = document.getElementsByTagName("body")[0];
  const clientWidth = docEl ? docEl.clientWidth : 0;
  const clientHeight = docEl ? docEl.clientHeight : 0;
  let width = Math.min(
    window.innerWidth || clientWidth || body.clientWidth,
    900
  );
  const height = Math.min(
    window.innerHeight || clientHeight || body.clientHeight,
    500
  );

  const padding = 10;

  // Mobile
  if (width >= 640) width /= 2.0;

  return {
    width: width - padding * 2,
    height: Math.min(width, height),
  };
}

function radToXY(d, width, height) {
  const rMid = (Math.sqrt(d.y1) + Math.sqrt(d.y0)) / 2.0;
  const angleMid = (d.x0 + d.x1) / 2.0 - Math.PI / 2.0;

  const x = Math.cos(angleMid) * rMid;
  const y = Math.sin(angleMid) * rMid;

  return {
    x: x + width / 2.0,
    y: y + height / 2.0,
  };
}

function colorNode(d, isHovering = false) {
  if (d.data.completed) return isHovering ? "#035252" : "#166";
  if (d.data.marked) return isHovering ? "#CE6600" : "#f81";

  return isHovering ? "#aaa" : "#ccc";
}

function renderSunburst(data, width, height, showTooltip, hideTooltip) {
  const radius = Math.min(width, height) / 2;

  const svg = d3
    .select("#sunburst")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`);

  const partition = d3.partition().size([Math.PI * 2, radius * radius]);

  const dataRoot = d3
    .hierarchy(data)
    .sum((d) => (d.children ? 0 : 1))
    .sort(() => -1);

  const arc = d3
    .arc()
    .startAngle((d) => d.x0 + 0.008)
    .endAngle((d) => d.x1 - 0.008)
    .innerRadius((d) => Math.sqrt(d.y0) + 1)
    .outerRadius((d) => Math.sqrt(d.y1) - 1);

  partition(dataRoot);

  const path = svg.datum(data).selectAll("path").data(dataRoot.descendants());

  path
    .enter()
    .append("path")
    .attr("display", (d) => (d.depth ? null : "none")) // hide inner ring
    .attr("d", arc)
    .style("stroke", "#efefef")
    .style("fill", (d) => colorNode(d))
    .on("mouseover", (d) => {
      d3.select(this).style("fill", () => colorNode(d, true));

      const { x, y } = radToXY(d, width, height);

      showTooltip(x, y, d.data.title, d.data.description);
    })
    .on("mouseout", (d) => {
      d3.select(this).style("fill", () => colorNode(d));
      hideTooltip();
    });
}

interface HoveredNode {
  x: number;
  y: number;
  title: string;
  description: string;
}

const Sunburst: FC = () => {
  const data = useSelector<StateType, DataStateType>((state) => state.data);
  const [hoveredNode, setHoveredNode] = useState<HoveredNode | null>(null);

  useEffect(() => {
    function showTooltip(x, y, title, description) {
      setHoveredNode({ x, y, title, description });
    }

    function hideTooltip() {
      setHoveredNode(null);
    }

    const { width, height } = getSize();

    d3.select("#sunburst").selectAll("*").remove();

    renderSunburst(data, width, height, showTooltip, hideTooltip);
  }, [data]);

  return (
    <div className="sunburst">
      <svg id="sunburst" className="sunburst__svg" />
      {hoveredNode && (
        <div
          id="tooltip"
          className="sunburst__tooltip"
          style={{ left: hoveredNode.x, top: hoveredNode.y }}
        >
          <h3>{hoveredNode.title}</h3>
          <p>{hoveredNode.description}</p>
        </div>
      )}
    </div>
  );
};

export default Sunburst;
