import React, { FC } from "react";
import styled, { css } from "styled-components";
import { COL_ORANGE, COL_GREEN } from "../constants/styles";
import { CardSize } from "./Node";

const ChildrenWrapper = styled.div`
  height: 30px;
  padding: 3px;
  flex-grow: 1;
`;

const ChildrenChild = styled.div<{ marked?: boolean; completed?: boolean }>`
  transition: all 0.2s;
  border: 1px solid #ccc;
  border-radius: 2px;
  height: 100%;
  margin: 0 auto;
  ${({ completed }) =>
    completed &&
    css`
      background-color: ${COL_GREEN};
    `}
  ${({ marked }) =>
    marked &&
    css`
      background-color: ${COL_ORANGE};
    `}
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0 2px;
  cursor: pointer;

  &:hover {
    ${ChildrenChild} {
      border: 1px solid #888;
    }
  }
`;
interface PropsT {
  data?: any; // TODO: Improve this
  size: CardSize;
  onClick: () => void;
}

const Children: FC<PropsT> = (props) => (
  <Wrapper onClick={props.onClick}>
    {props.data &&
      props.data.map((child, i) => (
        <ChildrenWrapper key={i}>
          <ChildrenChild
            completed={!!child.completed}
            marked={!!child.marked}
          />
        </ChildrenWrapper>
      ))}
  </Wrapper>
);

export default Children;
