import * as actions from "./actionTypes";

export const SAVABLE_ACTIONS = [
  actions.ADD_NODE,
  actions.ADD_NODE_BEFORE,
  actions.REMOVE_NODE,
  actions.ADD_CHILD,
  actions.UPDATE_TEXT,
  actions.SET_COMPLETED,
  actions.SET_MARKED,
  actions.MOVE_CHILD,
  actions.MOVE_CHILD_INTO,
  actions.MOVE_CHILD_UP_TIMES,
];

// Currently, they are the same
export const TRACKABLE_ACTIONS = SAVABLE_ACTIONS;
