import React, { FC } from "react";

import EditableTextForm from "./EditableTextForm";
import styled from "styled-components";
import { TextFieldType } from "../actions/dataActions";

const Wrapper = styled.div`
  width: 100%;
`;

export type EditableTextSize = "XS" | "S" | "M" | "L" | "XL";
const fontSize: { [s in EditableTextSize]: string } = {
  XS: "14px",
  S: "16px",
  M: "20px",
  L: "24px",
  XL: "30px",
};

const SxEditableTextForm = styled(EditableTextForm)<{ size: EditableTextSize }>`
  width: 100%;
  text-align: center;
  padding: 3px 0;

  font-size: ${({ size }) => fontSize[size]};
  line-height: 1.5;
`;

const Text = styled.p<{
  size: EditableTextSize;
  type: "title" | "description";
}>`
  cursor: pointer;
  font-size: ${({ size }) => fontSize[size]};
  margin: ${({ type }) => (type === "description" ? "10px 0 10px" : 0)};
  font-weight: ${({ type }) => (type === "title" ? "700" : "400")};
`;

interface PropsT {
  text: string | null;
  type: "title" | "description";
  size: EditableTextSize;
  editing: boolean;
  onSubmit: (type: TextFieldType, value: string) => void;
  onToggleEdit: () => void;
}

const EditableText: FC<PropsT> = ({
  text,
  type,
  size,
  editing,
  onSubmit,
  onToggleEdit,
}) => {
  return (
    <Wrapper>
      {editing ? (
        <SxEditableTextForm
          size={size}
          defaultValue={text}
          onSubmit={(txt: string) => {
            onSubmit(type, txt);
            onToggleEdit();
          }}
          onCancel={onToggleEdit}
        />
      ) : text ? (
        <Text size={size} type={type} onClick={onToggleEdit}>
          {text}
        </Text>
      ) : null}
    </Wrapper>
  );
};

export default EditableText;
