export function setAuthToken(token: string) {
  return localStorage.setItem("authToken", token);
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function removeAuthToken() {
  return localStorage.removeItem("authToken");
}
