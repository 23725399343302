import { OPEN_MODAL, CLOSE_MODAL } from "../constants/actionTypes";

import { ModalStateType } from "../reducers/modalReducer";

export const closeModal = () => ({ type: CLOSE_MODAL });

export const openModal = (modalType: ModalStateType) => ({
  type: OPEN_MODAL,
  payload: { modalType },
});
