import { useEffect, useCallback, RefObject } from "react";

type IHandler = (event: KeyboardEvent) => void;
const defaultHandler: IHandler = () => undefined;

export const useKeyPress = <T extends HTMLElement>(
  targetKey: string,
  handler: IHandler = defaultHandler,
  ref?: RefObject<T>
) => {
  const keyDownHandler = useCallback(
    (event: Event) => {
      if (
        event instanceof KeyboardEvent &&
        event.key &&
        event.key.toLowerCase() === targetKey.toLowerCase()
      ) {
        return handler(event);
      }
    },
    [handler, targetKey]
  );

  useEffect(() => {
    const current = ref ? ref.current : window;

    if (current) {
      current.addEventListener("keydown", keyDownHandler);
    }

    return () => {
      if (current) {
        current.removeEventListener("keydown", keyDownHandler);
      }
    };
  }, [ref, keyDownHandler]);
};
