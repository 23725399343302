import React, { useState, FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";

import { UserStateType } from "../../reducers/userReducer";

import { logout } from "../../actions/loginActions";
import { getData } from "../../actions/dataActions";
import { APP_ROUTE } from "../../constants/routes";

import Sunburst from "../Sunburst";
import ProfileStatistics from "../ProfileStatistics";

import AppFrame from "./AppFrame";
import { StateType } from "../../reducers";
import Icon from "../Icon";

const messages = [
  "Great to have you here.",
  "You look good today.",
  "Today is a great day to finish things.",
  "Let's get some things done.",
  "It's our privilege to hold your cards.",
  "We have a feeling, you're playing your cards well.",
  "Today is unique. You can do it.",
];

interface PropsT {
  location: Object;
}

const Profile: FC<PropsT> = ({ location }) => {
  const user = useSelector<StateType, UserStateType>((state) => state.user);
  const dispatch = useDispatch();

  const onLogout = () => dispatch(logout());

  const [msg] = useState<string>(
    messages[Math.floor(Math.random() * messages.length)]
  );

  useEffect(() => {
    if (!user.isLoggedIn) {
      dispatch(push("/"));
      return;
    }

    dispatch(getData());
  }, [user.isLoggedIn, dispatch]);

  return (
    <AppFrame location={location}>
      <div className="profile">
        <h2>{msg}</h2>
        <Link
          type="button"
          className="btn btn--small btn--green profile__button"
          to={APP_ROUTE}
        >
          <Icon icon="rocket" /> Go to cards
        </Link>
        <div className="profile__stats">
          <Sunburst />
          <ProfileStatistics />
        </div>
        <button
          type="button"
          className="btn btn--small btn--transparent profile__button"
          onClick={onLogout}
        >
          <Icon icon="sign-out-alt" /> Logout
        </button>
      </div>
    </AppFrame>
  );
};

export default Profile;
