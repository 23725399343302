import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";

import AppContainer from "../containers/AppContainer";

import {
  APP_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  PROFILE_ROUTE,
  IMPRINT_ROUTE,
  NOT_FOUND_ROUTE,
  VERSION_ROUTE,
} from "../constants/routes";

import Landing from "./pages/Landing";
import Profile from "./pages/Profile";
import Imprint from "./pages/Imprint";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NotFound from "./pages/NotFound";
import Version from "./pages/Version";
import ModalFrame from "./ModalFrame";

type PropsType = {
  history: any;
};

const AppRouter = (props: PropsType) => (
  <Router history={props.history}>
    <div>
      <ModalFrame />

      <Switch>
        <Route exact={true} path="/" component={Landing} />

        <Route path={APP_ROUTE} component={AppContainer} />
        <Route path={NOT_FOUND_ROUTE} component={NotFound} />
        <Route path={LOGIN_ROUTE} component={Login} />
        <Route path={SIGNUP_ROUTE} component={Signup} />
        <Route path={PROFILE_ROUTE} component={Profile} />
        <Route path={IMPRINT_ROUTE} component={Imprint} />
        <Route path={VERSION_ROUTE} component={Version} />

        <Redirect to={NOT_FOUND_ROUTE} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
