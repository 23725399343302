import React, { FC } from "react";
import ChildIconButton from "./ChildIconButton";
import styled from "styled-components";
import { CardSize } from "./Node";

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

const SxChildIconButton = styled(ChildIconButton)`
  flex-grow: 1;
`;

interface PropsT {
  white?: boolean;
  size: CardSize;
  onAddChildClick: () => void;
  onSetCompletedClick: () => void;
}

const ChildBottomButtons: FC<PropsT> = ({
  white,
  size,
  onAddChildClick,
  onSetCompletedClick,
}) => (
  <Wrapper>
    {
      <SxChildIconButton
        size={size}
        white={white}
        iconClassName="plus"
        title="Divide this card into subcards"
        onClick={onAddChildClick}
      />
    }
    {
      <SxChildIconButton
        size={size}
        white={white}
        iconClassName="check"
        title="Mark this card as 'completed'"
        onClick={onSetCompletedClick}
      />
    }
  </Wrapper>
);

export default ChildBottomButtons;
