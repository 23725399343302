import React, { FC } from "react";

type PropsT = {
  query: string;
};

const MediaQuery: FC<PropsT> = (props) => (
  <div className={props.query}>{props.children}</div>
);

export default MediaQuery;
