import { v4 as uuidv4 } from "uuid";

function newNode() {
  return {
    title: "Title",
    id: uuidv4(),
  };
}

function addChild(node, treePos, childIndex) {
  if (treePos.length === 0)
    return {
      ...node,
      completed: false,
      children: [
        ...node.children.slice(0, childIndex),
        {
          ...node.children[childIndex],
          children: [...(node.children[childIndex].children || []), newNode()],
        },
        ...node.children.slice(childIndex + 1),
      ],
    };

  const pos = treePos[0];

  return {
    ...node,
    completed: false,
    children: [
      ...node.children.slice(0, pos),
      addChild(node.children[pos], treePos.slice(1), childIndex),
      ...node.children.slice(pos + 1),
    ],
  };
}

// Adds a node at the same level
function addNode(node, treePos, before = false) {
  if (treePos.length === 0) {
    const children = before
      ? [newNode(), ...node.children]
      : [...node.children, newNode()];

    return {
      ...node,
      completed: false,
      children,
    };
  }

  const pos = treePos[0];

  return {
    ...node,
    completed: false,
    children: [
      ...node.children.slice(0, pos),
      addNode(node.children[pos], treePos.slice(1), before),
      ...node.children.slice(pos + 1),
    ],
  };
}

function removeNode(node, treePos, childIndex) {
  if (treePos.length === 0) {
    const children = [
      ...node.children.slice(0, childIndex),
      ...node.children.slice(childIndex + 1),
    ];

    return {
      ...node,
      completed:
        children.length !== 0 && children.every((child) => child.completed),
      marked: children.some((child) => child.marked),
      children,
    };
  }

  const pos = treePos[0];
  const children = [
    ...node.children.slice(0, pos),
    removeNode(node.children[pos], treePos.slice(1), childIndex),
    ...node.children.slice(pos + 1),
  ];

  return {
    ...node,
    completed:
      children.length !== 0 && children.every((child) => child.completed),
    marked: children.some((child) => child.marked),
    children,
  };
}

function updateText(node, treePos, childIndex, newTitle, newDescription) {
  if (treePos.length === 0) {
    const title = newTitle || node.children[childIndex].title; // "" Not allowed
    const description =
      typeof newDescription === "undefined"
        ? node.children[childIndex].description
        : newDescription;

    return {
      ...node,
      children: [
        ...node.children.slice(0, childIndex),
        {
          ...node.children[childIndex],
          title,
          description,
        },
        ...node.children.slice(childIndex + 1),
      ],
    };
  }

  const pos = treePos[0];

  return {
    ...node,
    children: [
      ...node.children.slice(0, pos),
      updateText(
        node.children[pos],
        treePos.slice(1),
        childIndex,
        newTitle,
        newDescription
      ),
      ...node.children.slice(pos + 1),
    ],
  };
}

function setCompleted(node, treePos, childIndex, completed) {
  if (treePos.length === 0) {
    const children = [
      ...node.children.slice(0, childIndex),
      {
        ...node.children[childIndex],
        completed,
        marked: completed ? false : node.children[childIndex].marked,
      },
      ...node.children.slice(childIndex + 1),
    ];

    return {
      ...node,
      completed: children.every((child) => child.completed),
      marked: children.some((child) => child.marked),
      children,
    };
  }

  const pos = treePos[0];
  const children = [
    ...node.children.slice(0, pos),
    setCompleted(node.children[pos], treePos.slice(1), childIndex, completed),
    ...node.children.slice(pos + 1),
  ];

  return {
    ...node,
    completed: children.every((child) => child.completed),
    marked: children.some((child) => child.marked),
    children,
  };
}

function setMarked(node, treePos, childIndex, marked) {
  if (treePos.length === 0) {
    const children = [
      ...node.children.slice(0, childIndex),
      {
        ...node.children[childIndex],
        marked,
      },
      ...node.children.slice(childIndex + 1),
    ];

    return {
      ...node,
      marked: children.some((child) => child.marked),
      children,
    };
  }

  const pos = treePos[0];
  const children = [
    ...node.children.slice(0, pos),
    setMarked(node.children[pos], treePos.slice(1), childIndex, marked),
    ...node.children.slice(pos + 1),
  ];

  return {
    ...node,
    marked: children.some((child) => child.marked),
    children,
  };
}

function moveChild(node, treePos, sourceIndex, targetIndex) {
  if (treePos.length === 0) {
    const childrenWithoutSourceEl = [
      ...node.children.slice(0, sourceIndex),
      ...node.children.slice(sourceIndex + 1),
    ];
    const children = [
      ...childrenWithoutSourceEl.slice(0, targetIndex),
      node.children[sourceIndex],
      ...childrenWithoutSourceEl.slice(targetIndex),
    ];

    return {
      ...node,
      children,
    };
  }

  const pos = treePos[0];
  const children = [
    ...node.children.slice(0, pos),
    moveChild(node.children[pos], treePos.slice(1), sourceIndex, targetIndex),
    ...node.children.slice(pos + 1),
  ];

  return {
    ...node,
    children,
  };
}

function moveChildInto(node, treePos, sourceIndex, targetIndex) {
  if (treePos.length === 0) {
    const insertedChildren = [
      ...(node.children[targetIndex].children || []),
      node.children[sourceIndex],
    ];
    const childrenWithCopiedSourceEl = [
      ...node.children.slice(0, targetIndex),
      {
        ...node.children[targetIndex],
        children: insertedChildren,
        completed: insertedChildren.every((child) => child.completed),
      },
      ...node.children.slice(targetIndex + 1),
    ];
    const children = [
      ...childrenWithCopiedSourceEl.slice(0, sourceIndex),
      ...childrenWithCopiedSourceEl.slice(sourceIndex + 1),
    ];

    return {
      ...node,
      children,
      completed: children.every((child) => child.completed),
    };
  }

  const pos = treePos[0];
  const children = [
    ...node.children.slice(0, pos),
    moveChildInto(
      node.children[pos],
      treePos.slice(1),
      sourceIndex,
      targetIndex
    ),
    ...node.children.slice(pos + 1),
  ];

  return {
    ...node,
    children,
  };
}

function getChildToMove(node, treePos, sourceIndex) {
  if (treePos.length === 0) return node.children[sourceIndex];

  const pos = treePos[0];

  return getChildToMove(node.children[pos], treePos.slice(1), sourceIndex);
}

function moveChildUpTimes(node, treePos, sourceIndex, times) {
  if (treePos.length === 0)
    return {
      ...node,
      children: [
        ...node.children.slice(0, sourceIndex),
        ...node.children.slice(sourceIndex + 1),
      ],
    };

  const pos = treePos[0];

  if (treePos.length === times) {
    const childToMove = getChildToMove(node, treePos, sourceIndex);

    return {
      ...node,
      children: [
        ...node.children.slice(0, pos),
        moveChildUpTimes(
          node.children[pos],
          treePos.slice(1),
          sourceIndex,
          times
        ),
        ...node.children.slice(pos + 1),
        childToMove,
      ],
    };
  }

  const children = [
    ...node.children.slice(0, pos),
    moveChildUpTimes(node.children[pos], treePos.slice(1), sourceIndex, times),
    ...node.children.slice(pos + 1),
  ];

  return { ...node, children };
}

function replace(state, payload) {
  const children =
    !payload.data.data || !payload.data.data.children
      ? state.children
      : payload.data.data.children;

  return {
    children,
    loadingGet: false,
    loadingSave: false,
    errorGet: state.errorGet,
    errorSave: state.errorSave,
    lastUpdatedAt: payload.data.updatedAt,
  };
}

function toggleEditing(node, action, editingAttribute) {
  const { treePosition, childIndex } = action.payload;

  if (treePosition.length === 0) {
    const children = [
      ...node.children.slice(0, childIndex),
      {
        ...node.children[childIndex],
        [editingAttribute]: !node.children[childIndex][editingAttribute],
      },
      ...node.children.slice(childIndex + 1),
    ];

    return { ...node, children };
  }

  const pos = treePosition[0];
  const children = [
    ...node.children.slice(0, pos),
    toggleEditing(
      node.children[pos],
      { payload: { childIndex, treePosition: treePosition.slice(1) } },
      editingAttribute
    ),
    ...node.children.slice(pos + 1),
  ];

  return { ...node, children };
}

function toggleEditDescription(state, action) {
  return toggleEditing(state, action, "editingDescription");
}

function toggleEditTitle(state, action) {
  return toggleEditing(state, action, "editingTitle");
}

export type CardType = {
  id: string;
  title?: string; // Actually this should be required, but we get crazy recursive flow errors
  description?: string;
  completed?: boolean;
  marked?: boolean;
  editingTitle?: boolean;
  editingDescription?: boolean;
  children: CardType[];
};

export type DataStateType = {
  loadingGet: boolean;
  loadingSave: boolean;
  lastSaved?: number;
  lastUpdatedAt?: number;
  errorGet: string | null;
  errorSave: boolean;
  children: CardType[];
};

const initialState: DataStateType = {
  errorGet: null,
  errorSave: false,
  loadingGet: false,
  loadingSave: false,
  children: [
    {
      id: uuidv4(),
      title: "This is your first card",
      description:
        "Play around! Try out all of the buttons. " +
        "Sign up to sync cards across your devices. Have fun!",
      children: [],
    },
  ],
};

function data(state: DataStateType = initialState, action: any): DataStateType {
  const { payload } = action;

  switch (action.type) {
    case "ADD_NODE":
      return addNode(state, payload.treePosition);
    case "ADD_NODE_BEFORE":
      return addNode(state, payload.treePosition, true);
    case "REMOVE_NODE":
      return removeNode(state, payload.treePosition, payload.childIndex);
    case "ADD_CHILD":
      return addChild(state, payload.treePosition, payload.childIndex);
    case "UPDATE_TEXT":
      return updateText(
        state,
        payload.treePosition,
        payload.childIndex,
        payload.title,
        payload.description
      );
    case "SET_COMPLETED":
      return setCompleted(
        state,
        payload.treePosition,
        payload.childIndex,
        payload.completed
      );
    case "SET_MARKED":
      return setMarked(
        state,
        payload.treePosition,
        payload.childIndex,
        payload.marked
      );
    case "MOVE_CHILD":
      return moveChild(
        state,
        payload.treePosition,
        payload.sourceIndex,
        payload.targetIndex
      );
    case "MOVE_CHILD_INTO":
      return moveChildInto(
        state,
        payload.treePosition,
        payload.sourceIndex,
        payload.targetIndex
      );
    case "MOVE_CHILD_UP_TIMES":
      return moveChildUpTimes(
        state,
        payload.treePosition,
        payload.sourceIndex,
        payload.times
      );
    case "GET_DATA_START":
      return { ...state, loadingGet: true };
    case "GET_DATA_SUCCESS":
      return replace(state, payload);
    case "GET_DATA_ERROR":
      return { ...state, loadingGet: false, errorGet: payload.message };
    case "PATCH_DATA_START":
      return { ...state, loadingSave: true };
    case "PATCH_DATA_SUCCESS":
      return {
        ...state,
        loadingSave: false,
        lastSaved: payload.receivedAt, // Client side time stamp
        lastUpdatedAt: payload.data.updatedAt, // Server side time stamp
      };
    case "PATCH_DATA_ERROR":
      return { ...state, loadingSave: false, errorSave: payload.message };
    case "LOGOUT":
      return initialState;
    case "TOGGLE_EDIT_TITLE":
      return toggleEditTitle(state, action);
    case "TOGGLE_EDIT_DESCRIPTION":
      return toggleEditDescription(state, action);
    default:
      return state;
  }
}

export default data;
