import React from "react";
import PathNavigationNode from "./PathNavigationNode";
import { ParentNode } from "./Node";

import logo from "../../images/amplitudefive-logo.png";

function shortenText(text: string, maxlen: number): string {
  if (text.length > maxlen) return `${text.substring(0, maxlen)}...`;

  return text;
}

type PropsType = {
  parentNodes: ParentNode[];
  onNavigateUpTimes: Function;
  onMoveChildUpTimes: Function;
};

const PathNavigation = (props: PropsType) => {
  const betweenNodes = props.parentNodes.slice(0, props.parentNodes.length - 1);
  const lastNode = props.parentNodes[props.parentNodes.length - 1];

  return (
    <div className="path-navigation">
      <div className="path-navigation__wrap">
        <PathNavigationNode
          onNavigateUp={() => props.onNavigateUpTimes(props.parentNodes.length)}
          onMoveChildUp={(sourceIndex) =>
            props.onMoveChildUpTimes(sourceIndex, props.parentNodes.length)
          }
        >
          <img alt="" src={logo} className="path-navigation__logo" />
        </PathNavigationNode>
        {betweenNodes.map((node, i, nodes) => (
          <PathNavigationNode
            key={i}
            onNavigateUp={() => props.onNavigateUpTimes(nodes.length - i)}
            onMoveChildUp={(sourceIndex) =>
              props.onMoveChildUpTimes(sourceIndex, nodes.length - i)
            }
          >
            <h2 className="path-navigation__content__headline">
              {shortenText(node.title, 20)}
            </h2>
          </PathNavigationNode>
        ))}
        {lastNode && (
          <div className="path-navigation__last-node">
            <div className="path-navigation__content path-navigation__last-node__content">
              <h2 className="path-navigation__content__headline">
                {lastNode.title}
              </h2>
              {lastNode.description && (
                <p className="path-navigation__content__description">
                  {lastNode.description}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PathNavigation;
