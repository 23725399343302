import { push } from "connected-react-router";

import {
  TREE_POSITION_INIT,
  TREE_POSITION_UP,
  TREE_POSITION_INTO,
} from "../constants/actionTypes";

import { APP_ROUTE } from "../constants/routes";

import {
  getSlugFromCards,
  getSlugsFromCards,
  getSlugsFromPath,
  isAppRoute,
} from "../helpers/routesHelper";

import { removeTrailingSlash } from "../helpers/commonHelper";

import { TreePositionType } from "../reducers/treePositionReducer";

import { DataStateType, CardType } from "../reducers/dataReducer";

const getTreePositionFromDataAndSlugs = (
  node: { children: CardType[] },
  slugs: string[]
): number[] => {
  // Try to find these slugs in our data now.
  // When we cannot find one of our slugs, this card is either still loading
  // or it does not exist.
  if (slugs.length === 0) return [];

  if (!node || !node.children) return []; // Data not loaded yet

  const childrenSlugs = getSlugsFromCards(node.children);
  const pos = childrenSlugs.indexOf(slugs[0]);

  if (pos === -1) return []; // Data not loaded yet

  const nextPos = node.children
    ? getTreePositionFromDataAndSlugs(node.children[pos], slugs.slice(1))
    : [];

  return [pos, ...nextPos];
};

const getTreePosition = (
  data: DataStateType,
  pathname: string
): TreePositionType => {
  if (!pathname || isAppRoute(pathname)) return [];

  // First determine these slugs
  const slugs = getSlugsFromPath(pathname);
  const position = getTreePositionFromDataAndSlugs(data, slugs);

  return slugs.length !== position.length
    ? [] // Only found the route partially
    : position;
};

export const treePositionInit = (data: DataStateType, pathname: string) => (
  dispatch: any
) => {
  const treePosition = getTreePosition(data, pathname);

  return treePosition.length !== 0
    ? dispatch({
        type: TREE_POSITION_INIT,
        payload: { treePosition },
      })
    : dispatch(push(APP_ROUTE));
};

export const treePositionUp = (times: number) => ({
  type: TREE_POSITION_UP,
  payload: { times },
});
export const treePositionInto = (childIndex: number) => ({
  type: TREE_POSITION_INTO,
  payload: { childIndex },
});

export const navigateInto = (
  pathname: string,
  children: CardType[],
  childIndex: number
) => (dispatch: any) => {
  const nextSlug = getSlugFromCards(children, childIndex);
  const currentPath = removeTrailingSlash(pathname);
  const nextPath = [currentPath, nextSlug].join("/");

  dispatch(treePositionInto(childIndex));

  return dispatch(push(nextPath));
};

export const navigateUpTimes = (pathname: string, times: number) => (
  dispatch: any
) => {
  if (isAppRoute(pathname)) return null;

  const slugs = pathname.split("/");
  const nextPath = slugs.slice(0, slugs.length - times).join("/");

  dispatch(treePositionUp(times));

  return dispatch(push(nextPath));
};
