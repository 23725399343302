import React, { FC } from "react";
import Modal, { ModalPropsT } from "./Modal";

const ModalContainer: FC<ModalPropsT> = (props) => (
  <div className="modal-container">
    <Modal {...props} />
  </div>
);

export default ModalContainer;
