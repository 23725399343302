import React from "react";
import { connect } from "react-redux";

import actions from "../../actions";

import AppFrame from "./AppFrame";
import LoginSignupForm from "../LoginSignupForm";

import { UserStateType } from "../../reducers/userReducer";

interface PropsT {
  location: Object;
  user: UserStateType;
  signUp: Function;
  closeModal: Function;
}

const Signup = (props: PropsT) => (
  <AppFrame location={props.location}>
    <div className="login">
      <LoginSignupForm
        type="signup"
        onSubmit={props.signUp}
        submitLabel="Sign Up"
        error={props.user.signUp.error}
        loading={props.user.signUp.loading}
      />
    </div>
  </AppFrame>
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    signUp: (email, password) => dispatch(actions.signUp(email, password)),
    closeModal: () => dispatch(actions.closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
