import * as serviceWorker from "./serviceWorker";

import React from "react";
import ReactDOM from "react-dom";

import { compose, createStore, applyMiddleware } from "redux";

import { Provider } from "react-redux";

import { createBrowserHistory } from "history";

import "./styles/styles.sass";
import "./images/dicore-og.png";

import "./js/localization";

import reducers from "./js/reducers";
import AppRouter from "./js/components/AppRouter";

import { IS_PRODUCTION } from "./js/constants/environment";

import createMiddleware from "./js/middlewares/prodMiddleware";

// ---------------------
// NORMAL REDUX
// ---------------------
// const store = createStore(reducers, initialState);

function makeStore(initialState: any, middleware: any) {
  let enhancer;

  if (!IS_PRODUCTION) enhancer = compose(middleware);
  else enhancer = compose(middleware);

  return createStore(reducers, initialState, enhancer);
}

const browserHistory = createBrowserHistory();

const initialState = {};

const middleware = applyMiddleware(...createMiddleware(browserHistory));

const store = makeStore(initialState, middleware);

// const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppRouter history={browserHistory} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
