import { OPEN_MODAL, CLOSE_MODAL } from "../constants/actionTypes";
import { ModalTypes } from "../constants/modalTypes";

export type ModalStateType = keyof ModalTypes | null;

const initialState: ModalStateType = null;

function modal(
  state: ModalStateType = initialState,
  action: any
): ModalStateType {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload.modalType;
    case CLOSE_MODAL:
      return null;
    default:
      return state;
  }
}

export default modal;
