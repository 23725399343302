import { includes } from "../helpers/commonHelper";
import { TRACKABLE_ACTIONS } from "../constants/actionCollections";
import { IS_PRODUCTION } from "../constants/environment";
import {
  UPDATE_TEXT,
  SET_COMPLETED,
  SET_MARKED,
} from "../constants/actionTypes";

const updateText = (action) => {
  const field = action.payload.description ? "description" : "title";

  (window as any).ga("send", "event", "action", `${action.type}-${field}`);
};

const trackingMiddleware = () => (next) => (action) => {
  next(action);

  if (!IS_PRODUCTION) return; // Don't track when developing

  if (includes(TRACKABLE_ACTIONS, action.type))
    switch (action.type) {
      case SET_COMPLETED:
        (window as any).ga(
          "send",
          "event",
          "action",
          `${action.type}-${action.payload.completed}`
        );
        break;
      case SET_MARKED:
        (window as any).ga(
          "send",
          "event",
          "action",
          `${action.type}-${action.payload.marked}`
        );
        break;
      case UPDATE_TEXT:
        updateText(action);
        break;
      default:
        // ALL OTHER ACTIONS
        (window as any).ga("send", "event", "action", `${action.type}`);
    }
};

export default trackingMiddleware;
