import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";

import actions from "../actions";

import EditableText, { EditableTextSize } from "./EditableText";
import Children from "./Children";
import ChildBottomButtons from "./ChildBottomButtons";
import ChildTopButtons from "./ChildTopButtons";
import { MOVE_INTO } from "../constants/childMoveModeTypes";
import { StateType } from "../reducers";
import { TreePositionStateType } from "../reducers/treePositionReducer";
import { CardType } from "../reducers/dataReducer";
import { TextFieldType } from "../actions/dataActions";
import styled, { css } from "styled-components";
import ChildIconButton from "./ChildIconButton";
import {
  COL_BLACK,
  COL_ORANGE,
  COL_GREEN,
  MEDIA_MAX_S,
} from "../constants/styles";
import { CardSize } from "./Node";

const Wrapper = styled.div<{
  over?: boolean;
  into?: boolean;
  draggingIntoSelf?: boolean;
  marked?: boolean;
  completed?: boolean;
  size: CardSize;
}>`
  position: relative;
  margin: 5px;
  transition: color, background-color 0.2s;
  height: 100%;
  width: ${({ size }) =>
    size === "L"
      ? "400px"
      : size === "M"
      ? "300px"
      : size === "S"
      ? "200px"
      : "100%"};
  text-align: center;
  padding: ${({ size }) => (size === "S" ? "30px 8px" : "50px 15px")};
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &:hover {
    ${ChildIconButton} {
      display: initial;
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }
  }

  ${({ over }) =>
    over &&
    css`
      border: 1px dashed ${COL_BLACK};
      background-color: #fff !important;
      opacity: 0.5;
      div {
        opacity: 0;
      }
    `};

  ${({ into }) =>
    into &&
    css`
      border: 1px solid ${COL_GREEN};
    `};

  ${({ draggingIntoSelf }) =>
    draggingIntoSelf &&
    css`
      opacity: 0;
    `};

  ${({ completed }) =>
    completed &&
    css`
      color: #fff;
      background-color: ${COL_GREEN};
    `};

  ${({ marked }) =>
    marked &&
    css`
      color: #fff;
      background-color: ${COL_ORANGE};
    `};

  @media ${MEDIA_MAX_S} {
    padding: 40px 10px;
    width: 100%;
  }
`;

interface DragItem {
  index: number;
  type: string;
}

interface PropsT {
  index: number;
  node: CardType;
  size: CardSize;
  onNavigateInto: Function;
  onMoveChildInto: Function;
}

const titleSize: { [s in CardSize]: EditableTextSize } = {
  S: "M",
  M: "L",
  L: "XL",
};

const descriptionSize: { [s in CardSize]: EditableTextSize } = {
  S: "XS",
  M: "S",
  L: "M",
};

const Child: FC<PropsT> = ({
  index,
  node,
  size,
  onNavigateInto,
  onMoveChildInto,
}) => {
  const {
    title,
    description,
    children,
    completed,
    marked,
    editingTitle,
    editingDescription,
  } = node;

  const treePosition = useSelector<StateType, TreePositionStateType>(
    (state) => state.treePosition
  );
  const hasChildren = children ? children.length > 0 : false;
  const isMoveModeInto = useSelector<StateType, boolean>(
    (state) => state.childMoveMode === MOVE_INTO
  );

  const dispatch = useDispatch();

  const onEditTitleToggle = () =>
    dispatch(actions.toggleEditTitle(treePosition, index));
  const onEditDescriptionToggle = () =>
    dispatch(actions.toggleEditDescription(treePosition, index));
  const onAddChild = () => dispatch(actions.addChild(treePosition, index));
  const onUpdateText = (type: TextFieldType, text: string) =>
    dispatch(actions.updateText(treePosition, index, type, text));
  const onRemove = () => dispatch(actions.remove(treePosition, index));
  const onSetCompleted = (completed: boolean) =>
    dispatch(actions.setCompleted(treePosition, index, completed));
  const onSetMarked = (marked: boolean) =>
    dispatch(actions.setMarked(treePosition, index, marked));
  const onMoveChild = (sourceIndex: number, targetIndex: number) =>
    dispatch(actions.moveChild(treePosition, sourceIndex, targetIndex));

  const dragItem: DragItem = {
    index,
    type: "child",
  };

  const [{ isDragging }, drag] = useDrag({
    item: dragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop<DragItem, void, { isOver: boolean }>({
    accept: "child",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      if (!isMoveModeInto) return;

      const targetIndex = index;
      const sourceIndex = item.index;

      if (sourceIndex === targetIndex) return;

      onMoveChildInto(sourceIndex, targetIndex);
    },
    hover: (item) => {
      if (isMoveModeInto) return;

      const targetIndex = index;
      const sourceIndex = item.index;

      if (sourceIndex === targetIndex) return;

      onMoveChild(sourceIndex, targetIndex);
      // Perform reordering
      item.index = targetIndex;
    },
  });

  return (
    <Wrapper
      size={size}
      into={isOver && isMoveModeInto}
      over={isOver && !isMoveModeInto}
      draggingIntoSelf={isDragging && isMoveModeInto}
      completed={!!completed}
      marked={!!marked && !hasChildren}
      ref={(instance) => {
        drag(drop(instance));
      }}
    >
      <ChildTopButtons
        white={marked || completed}
        size={size}
        completed={!!completed}
        hasChildren={hasChildren}
        hasDescription={!!description}
        onEditDescriptionClick={onEditDescriptionToggle}
        onRemoveClick={onRemove}
        onMarkClick={() => {
          if (completed) return;

          onSetMarked(!marked);
        }}
      />
      <EditableText
        type="title"
        text={title}
        size={titleSize[size]}
        editing={!!editingTitle}
        onSubmit={onUpdateText}
        onToggleEdit={onEditTitleToggle}
      />
      <EditableText
        type="description"
        text={description}
        size={descriptionSize[size]}
        editing={!!editingDescription}
        onSubmit={onUpdateText}
        onToggleEdit={onEditDescriptionToggle}
      />
      {hasChildren ? (
        <Children
          size={size}
          data={children}
          onClick={() => onNavigateInto(index)}
        />
      ) : (
        <ChildBottomButtons
          size={size}
          white={marked || completed}
          onAddChildClick={() => {
            onAddChild();
            onNavigateInto(index);
          }}
          onSetCompletedClick={() => onSetCompleted(!completed)}
        />
      )}
    </Wrapper>
  );
};

export default Child;
