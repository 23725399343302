export const RELOAD_MODAL = "RELOAD_MODAL";
export const HELP_MODAL = "HELP_MODAL";

export interface ModalTypes {
  RELOAD_MODAL: string;
  HELP_MODAL: string;
}

export const MODAL_TYPES: ModalTypes = {
  RELOAD_MODAL,
  HELP_MODAL,
};
