import React, { FC } from "react";
import { useSelector } from "react-redux";

import { StateType } from "../reducers";
import { DataStateType } from "../reducers/dataReducer";

const ps = "profile-statistics";

const ProfileStatistics: FC = () => {
  const { totalCards, completedCards, markedCards } = useDataStatistics();

  return (
    <div className={ps}>
      <p className={`${ps}__row ${ps}__total`}>{totalCards} Total cards</p>
      <div
        className={`${ps}__row ${ps}__border ${ps}__border__total`}
        style={{ width: "100%" }}
      />
      <p className={`${ps}__row ${ps}__completed`}>
        {completedCards} Completed
      </p>
      <div
        className={`${ps}__row ${ps}__border ${ps}__border__completed`}
        style={{
          width: `${Math.round((completedCards / totalCards) * 100)}%`,
        }}
      />
      <p className={`${ps}__row ${ps}__marked`}>{markedCards} Pinned</p>
      <div
        className={`${ps}__row ${ps}__border ${ps}__border__marked`}
        style={{
          width: `${Math.round((markedCards / totalCards) * 100)}%`,
        }}
      />
    </div>
  );
};

const useDataStatistics = () => {
  const data = useSelector<StateType, DataStateType>((state) => state.data);

  return dataStatistics(data, 0);
};

function dataStatistics(data, depth) {
  if (!data.children || data.children.length === 0)
    return {
      totalCards: 1,
      completedCards: data.completed ? 1 : 0,
      markedCards: data.marked ? 1 : 0,
    };

  const nodeStats = data.children.reduce(
    (stats, child) => {
      const childStats = dataStatistics(child, depth + 1);

      stats.totalCards += childStats.totalCards;
      stats.completedCards += childStats.completedCards;
      stats.markedCards += childStats.markedCards;

      return stats;
    },
    {
      totalCards: 0,
      completedCards: 0,
      markedCards: 0,
    }
  );

  if (depth !== 0) nodeStats.totalCards += 1;
  if (data.completed) nodeStats.completedCards += 1;
  if (data.marked) nodeStats.markedCards += 1;

  return nodeStats;
}

export default ProfileStatistics;
