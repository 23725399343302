export const KEY_CODE_ZERO = 48;

export const ALLOWED_CMD_CTRL_KEY_CODES = [
  17, // CTRL in all browsers
  91, // Left CMD in webpack browsers
  93, // Right CMD in webpack browsers
  224, // Both CMDs in Firefox
];

export const KEY_BACKSPACE = 8;
export const KEY_ESC = 27;
export const KEY_A = 65;
export const KEY_U = 85;
export const KEY_Z = 90;
