import React, { FC } from "react";
import ChildIconButton from "./ChildIconButton";
import styled, { css } from "styled-components";
import { CardSize } from "./Node";

const Container = styled.div<{ left?: boolean; right?: boolean }>`
  position: absolute;
  top: 0;

  ${({ left }) =>
    left &&
    css`
      left: 0;
    `}
  ${({ right }) =>
    right &&
    css`
      right: 0;
    `}
`;

interface PropsT {
  white?: boolean;
  size: CardSize;
  completed: boolean;
  hasChildren: boolean;
  hasDescription: boolean;
  onEditDescriptionClick: () => void;
  onRemoveClick: () => void;
  onMarkClick: () => void;
}

const ChildTopButtons: FC<PropsT> = ({ white, size, ...props }) => (
  <>
    <Container left>
      {!props.hasChildren && !props.completed && (
        <ChildIconButton
          size={size}
          white={white}
          iconClassName="thumbtack"
          onClick={props.onMarkClick}
          title={
            "Pin this card. Helps you find it from the very top. " +
            "Useful to set a card 'in progress'"
          }
        />
      )}
      {!props.hasDescription && (
        <ChildIconButton
          size={size}
          white={white}
          iconClassName="align-left"
          onClick={props.onEditDescriptionClick}
          title="Add a description"
        />
      )}
    </Container>
    <Container right>
      <ChildIconButton
        size={size}
        white={white}
        iconClassName="trash"
        title="Remove this card (to undo press 'u')"
        onClick={props.onRemoveClick}
      />
    </Container>
  </>
);

export default ChildTopButtons;
