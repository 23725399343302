import { push } from "connected-react-router";
import * as api from "../api";

import { defaultSuccess, defaultError } from "./genericActions";

import { getData } from "./dataActions";

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  LOGOUT,
} from "../constants/actionTypes";

import { APP_ROUTE } from "../constants/routes";

const loginStart = () => ({ type: LOGIN_START });
const loginError = (err: any) => defaultError(LOGIN_ERROR, err);
const loginSuccess = (res: any) => defaultSuccess(LOGIN_SUCCESS, res);

export const login = (email: string, password: string) => (dispatch: any) => {
  dispatch(loginStart());

  return api.postAuthenticate(email, password).then(
    (r: any) => {
      dispatch(loginSuccess(r));
      dispatch(push(APP_ROUTE));

      return dispatch(getData({ init: true, pathname: APP_ROUTE }));
    },
    (e: any) => dispatch(loginError(e))
  );
};

const signUpStart = () => ({ type: SIGN_UP_START });
const signUpError = (err: any) => defaultError(SIGN_UP_ERROR, err);
const signUpSuccess = (res: any) => defaultSuccess(SIGN_UP_SUCCESS, res);

export const signUp = (email: string, password: string) => (dispatch: any) => {
  dispatch(signUpStart());

  return api.postUsers(email, password).then(
    (r: any) => {
      dispatch(signUpSuccess(r));

      return dispatch(login(email, password));
    },
    (e: any) => dispatch(signUpError(e))
  );
};

export const logout = () => ({ type: LOGOUT });
