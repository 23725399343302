import { TREE_POSITION_UP, TREE_POSITION_INTO } from "../constants/actionTypes";

export type AnimateDirectionStateT = string;

const initialState: AnimateDirectionStateT = "";

export default function animateDirection(
  state = initialState,
  action
): AnimateDirectionStateT {
  switch (action.type) {
    case TREE_POSITION_UP:
      return "up";
    case TREE_POSITION_INTO:
      return "down";
    default:
      return state;
  }
}
