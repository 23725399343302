import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE } from "../../constants/routes";

import AppFrame from "./AppFrame";
import CheckList from "../CheckList";

import {
  MEDIA_MAX_M,
  MAX_WIDTH,
  COL_GREEN,
  COL_ORANGE,
  MEDIA_MAX_S,
} from "../../constants/styles";

const SectionWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${MAX_WIDTH};
  padding: 80px 0 100px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${MEDIA_MAX_M} {
    padding: 50px 0;
  }

  h1 {
    font-size: 40px;
    @media ${MEDIA_MAX_M} {
      font-size: 32px;
    }
    @media ${MEDIA_MAX_S} {
      font-size: 24px;
    }
  }
  h2 {
    margin: 0 auto 20px;
    font-size: 60px;
    text-align: center;
    @media ${MEDIA_MAX_M} {
      font-size: 40px;
    }
    @media ${MEDIA_MAX_S} {
      font-size: 24px;
    }
  }
`;

const StartButton = styled(Link)`
  display: inline-block;
  text-align: center;
`;

const PreTitle = styled.p`
  margin: 0 auto 10px;
  font-size: 24px;
  @media ${MEDIA_MAX_M} {
    font-size: 16px;
  }
  text-transform: uppercase;
`;

const Section = styled.section`
  position: relative;
`;
const GreenSection = styled(Section)`
  color: white;
  background-color: ${COL_GREEN};
`;
const OrangeSection = styled(Section)`
  color: white;
  background-color: ${COL_ORANGE};
`;

const YtPlayerFrame = styled.div`
  position: relative;
`;

const YtPlayerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const YtPlayerIframe = styled.iframe`
  border: 1px solid ${COL_GREEN};
  margin: 20px auto 40px;
`;

interface PropsT {
  location: Object;
}

const Landing: FC<PropsT> = (props) => {
  const docEl = document.documentElement;
  const body = document.getElementsByTagName("body")[0];
  const width =
    window.innerWidth || (docEl && docEl.clientWidth) || body.clientWidth;

  // Determined by looking at the youtube video dimensions
  const padding = 20;
  const videoRatio = 1024 / 572;
  const videoWidth = Math.min(width, 1024) - padding * 2;
  const videoHeight = videoWidth / videoRatio;

  return (
    <AppFrame location={props.location}>
      <div>
        <GreenSection>
          <SectionWrap>
            <h2>
              The easiest productivity app
              <br />
              on the planet
            </h2>
            <CheckList
              items={[
                {
                  icon: "check-circle",
                  text: "Get things out of your head",
                },
                {
                  icon: "check-circle",
                  text: "Break down big goals into simple steps",
                },
                {
                  icon: "check-circle",
                  text: "Organize to your liking",
                },
              ]}
            />
            <StartButton className="btn btn--large btn--white" to={APP_ROUTE}>
              Go to the App
            </StartButton>
          </SectionWrap>
        </GreenSection>
        <Section>
          <SectionWrap>
            <PreTitle>Tutorial</PreTitle>
            <h2>Watch how it works</h2>
            <YtPlayerFrame>
              <YtPlayerPlaceholder>
                <h3>{"Looks like you'll get things done today!"}</h3>
                <p>Loading the tutorial . . .</p>
              </YtPlayerPlaceholder>
              <YtPlayerIframe
                className="ytplayer"
                title="Workflow tutorial"
                width={videoWidth}
                height={videoHeight}
                src="https://www.youtube.com/embed/v_GYUQD__i8?rel=0"
                frameBorder="0"
                allowFullScreen={true}
              />
            </YtPlayerFrame>
          </SectionWrap>
        </Section>
        <OrangeSection>
          <SectionWrap>
            <PreTitle>Todo list re-imagined</PreTitle>
            <h2>
              A fresh approach
              <br />
              to getting things done
            </h2>
            <CheckList
              items={[
                {
                  icon: "sitemap",
                  text: "Break cards into subcards. Infinitely.",
                },
                {
                  icon: "keyboard",
                  text: "Navigate using keyboard shortcuts.",
                },
                {
                  icon: "mobile-alt",
                  text: "Use cards across all devices.",
                },
              ]}
            />
            <StartButton className="btn btn--large btn--white" to={APP_ROUTE}>
              Go to the App
            </StartButton>
          </SectionWrap>
        </OrangeSection>
      </div>
    </AppFrame>
  );
};

export default Landing;
