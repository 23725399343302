import { MEDIA_MAX_M } from "../constants/styles";
import styled from "styled-components";
import IconButton from "./IconButton";
import { CardSize } from "./Node";

const ChildIconButton = styled(IconButton)<{ size: CardSize }>`
  padding: ${({ size }) =>
    size === "S" ? "10px 18px" : size === "M" ? "12px 18px" : "15px 18px"};
  font-size: ${({ size }) =>
    size === "S" ? "14px" : size === "M" ? "16px" : "20px"};

  border: 0;
  background: transparent;
  display: none;
  opacity: 0;
  @media ${MEDIA_MAX_M} {
    display: initial;
    opacity: 0.3;
  }
`;
export default ChildIconButton;
