import {
  TREE_POSITION_UP,
  TREE_POSITION_INTO,
  TREE_POSITION_INIT,
  LOGIN_SUCCESS,
  SIGN_UP_SUCCESS,
} from "../constants/actionTypes";

const initialState: TreePositionStateType = [];

export type TreePositionType = number[];

export type TreePositionStateType = TreePositionType;

function treePosition(
  state: TreePositionStateType = initialState,
  action: any
): TreePositionStateType {
  switch (action.type) {
    case TREE_POSITION_INIT:
      return action.payload.treePosition;
    case TREE_POSITION_UP:
      // Remove last element
      return state.slice(0, state.length - action.payload.times);
    case TREE_POSITION_INTO:
      return state.concat([action.payload.childIndex]);
    case LOGIN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return [];
    default:
      return state;
  }
}

export default treePosition;
