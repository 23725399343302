import React from "react";
import { connect } from "react-redux";
import CSSTransitionGroup from "react-addons-css-transition-group";

import HelpModal from "./modals/HelpModal";
import ReloadModal from "./modals/ReloadModal";

import * as modalTypes from "../constants/modalTypes";

type PropsType = {
  openModal: string;
};

const ModalFrame = (props: PropsType) => {
  let modal = null;

  if (props.openModal === modalTypes.RELOAD_MODAL) modal = <ReloadModal />;
  else if (props.openModal === modalTypes.HELP_MODAL) modal = <HelpModal />;

  return (
    <CSSTransitionGroup
      transitionName="modal"
      transitionEnterTimeout={200}
      transitionLeaveTimeout={150}
    >
      {modal}
    </CSSTransitionGroup>
  );
};

const mapStateToProps = (state) => ({
  openModal: state.modal,
});

export default connect(mapStateToProps)(ModalFrame);
