import { SET_CHILD_MOVE_MODE } from "../constants/actionTypes";
import { ChildMoveNodeTypes } from "../constants/childMoveModeTypes";

export type ChildMoveModeStateType = keyof ChildMoveNodeTypes;

const initialState: ChildMoveModeStateType = "MOVE_SAME_LEVEL";

export default function childMoveModeReducer(
  state: ChildMoveModeStateType = initialState,
  action: any
): ChildMoveModeStateType {
  switch (action.type) {
    case SET_CHILD_MOVE_MODE:
      return action.payload.mode;
    default:
      return state;
  }
}
