import React, { FC } from "react";
import { DropTarget } from "react-dnd";

const cardTarget = {
  drop(props, monitor) {
    const sourceIndex = monitor.getItem().index;

    props.onMoveChildUp(sourceIndex);
  },
};

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

interface PropsT {
  isOver: boolean;
  connectDropTarget: Function;
  onNavigateUp: Function;
  onMoveChildUp: Function;
}

const PathNavigationNode: FC<PropsT> = (props) => {
  const classes =
    "path-navigation__content " +
    "path-navigation__node__content " +
    `${props.isOver ? "over" : ""}`;

  return props.connectDropTarget(
    <div className="path-navigation__node">
      <div className={classes} onClick={() => props.onNavigateUp()}>
        {props.children}
      </div>
      <div className="path-navigation__arrow" />
    </div>
  );
};

export default DropTarget("child", cardTarget, collectDrop)(PathNavigationNode);
