import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import saveDataMiddleware from "./saveDataMiddleware";
import trackingMiddleware from "./trackingMiddleware";

export default function (browserHistory) {
  const reduxRouterMiddleware = routerMiddleware(browserHistory);

  return [thunk, saveDataMiddleware, trackingMiddleware, reduxRouterMiddleware];
}
