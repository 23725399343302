import React from "react";
import { connect } from "react-redux";

import ModalContainer from "./ModalContainer";

import actions from "../../actions";
import { StateType } from "../../reducers";
import Icon from "../Icon";

interface PropsT {
  message: string;
  getData: () => void;
  closeModal: () => void;
}

const ReloadModal = (props: PropsT) => (
  <ModalContainer onCloseModal={props.closeModal} includesCloseIcon={true}>
    <div className="reload-modal">
      <h2 className="reload-modal__msg">{props.message}</h2>
      <button
        className="reload-modal__btn btn btn--small btn--white"
        onClick={props.getData}
      >
        <Icon className="reload-modal__btn__icon" icon="sync-alt" />
        Reload
      </button>
    </div>
  </ModalContainer>
);

function getErrorMessage(getError: string | null, errorSave: boolean) {
  if (getError) return getError;
  if (errorSave) return errorSave;

  return "Could not sync with the big wide internet";
}

function mapStateToProps(state: StateType) {
  return {
    message: getErrorMessage(state.data.errorGet, state.data.errorSave),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    closeModal: () => dispatch(actions.closeModal()),
    getData: () => dispatch(actions.getData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReloadModal);
