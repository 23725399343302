import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import actions from "../actions";

import {
  LANDING_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  APP_ROUTE,
} from "../constants/routes";

import { HELP_MODAL } from "../constants/modalTypes";

import DataSync from "./DataSync";
import { StateType } from "../reducers";

import logo from "../../images/amplitudefive-logo.png";

interface PropsType {
  isLoggedIn: boolean;
  location: any;
  openHelpModal: () => void;
}

const Header = (props: PropsType) => (
  <div className="header">
    <div className="header__wrap">
      <Link className="header__logo btn-reset" to={APP_ROUTE}>
        <img className="header__logo__img" alt="Logo" src={logo} />
      </Link>
      <div className="header__links">
        {props.location.pathname !== LANDING_ROUTE && (
          <Link
            to={LANDING_ROUTE}
            title="Shows the tutorial video again"
            className="header__link"
          >
            Tutorial
          </Link>
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          title="Takes you to a survey. Thanks already!"
          className="md-bigger header__link"
          href="https://kadrian1.typeform.com/to/UTPLJ2"
        >
          Feedback
        </a>
        <button
          className="md-bigger header__link"
          title="Opens an explanation with keyboard shortcuts"
          onClick={props.openHelpModal}
        >
          Shortcuts
        </button>
        {props.isLoggedIn ? (
          <Link to={PROFILE_ROUTE} title="Profile" className="header__link">
            Profile
          </Link>
        ) : (
          <Link to={LOGIN_ROUTE} title="Log in" className="header__link">
            Login
          </Link>
        )}
        {props.isLoggedIn && <DataSync />}
      </div>
    </div>
  </div>
);

function mapStateToProps(state: StateType) {
  return {
    isLoggedIn: state.user.isLoggedIn,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  openHelpModal: () => dispatch(actions.openModal(HELP_MODAL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
