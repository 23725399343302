import * as api from "../api";

import {
  GET_DATA_START,
  GET_DATA_ERROR,
  GET_DATA_SUCCESS,
  PATCH_DATA_START,
  PATCH_DATA_ERROR,
  PATCH_DATA_SUCCESS,
  UNDO,
  ADD_NODE,
  ADD_NODE_BEFORE,
  REMOVE_NODE,
  ADD_CHILD,
  UPDATE_TEXT,
  SET_COMPLETED,
  SET_MARKED,
  MOVE_CHILD,
  MOVE_CHILD_INTO,
  MOVE_CHILD_UP_TIMES,
  TOGGLE_EDIT_TITLE,
  TOGGLE_EDIT_DESCRIPTION,
} from "../constants/actionTypes";

import { treePositionInit } from "./navigateActions";
import { defaultSuccess, defaultError } from "./genericActions";
import { TreePositionStateType } from "../reducers/treePositionReducer";
import { DataStateType } from "../reducers/dataReducer";

const getDataStart = () => ({ type: GET_DATA_START });
const getDataError = (err: any) => defaultError(GET_DATA_ERROR, err);
const getDataSuccess = (res: any) => defaultSuccess(GET_DATA_SUCCESS, res);

type GetDataOptionsType = {
  init: boolean;
  pathname: string;
};

export const getData = (options?: GetDataOptionsType) => (dispatch: any) => {
  dispatch(getDataStart());

  return api.getData().then(
    (r: any) => {
      dispatch(getDataSuccess(r));

      if (options && options.init)
        dispatch(treePositionInit(r.data, options.pathname));
    },
    (e: any) => dispatch(getDataError(e))
  );
};

const patchDataStart = () => ({ type: PATCH_DATA_START });
const patchDataError = (err: any) => defaultError(PATCH_DATA_ERROR, err);
const patchDataSuccess = (res: any) => defaultSuccess(PATCH_DATA_SUCCESS, res);

export const patchData = (lastUpdatedAt: number, data: DataStateType) => (
  dispatch: any
) => {
  dispatch(patchDataStart());

  return api.patchData(lastUpdatedAt, data).then(
    (r: any) => dispatch(patchDataSuccess(r)),
    (e: any) => dispatch(patchDataError(e))
  );
};

export const undo = () => ({ type: UNDO });

export const addNode = (treePosition: TreePositionStateType) => ({
  type: ADD_NODE,
  payload: { treePosition },
});

export const addNodeBefore = (treePosition: TreePositionStateType) => ({
  type: ADD_NODE_BEFORE,
  payload: { treePosition },
});

export const remove = (
  treePosition: TreePositionStateType,
  childIndex: number
) => ({
  type: REMOVE_NODE,
  payload: { treePosition, childIndex },
});

export const addChild = (
  treePosition: TreePositionStateType,
  childIndex: number
) => ({
  type: ADD_CHILD,
  payload: { treePosition, childIndex },
});

export type TextFieldType = "title" | "description";

export const updateText = (
  treePosition: TreePositionStateType,
  childIndex: number,
  type: TextFieldType,
  text: string
) => {
  return {
    type: UPDATE_TEXT,
    payload: {
      treePosition,
      childIndex,
      [type]: text,
    },
  };
};

export const setCompleted = (
  treePosition: TreePositionStateType,
  childIndex: number,
  completed: boolean
) => ({
  type: SET_COMPLETED,
  payload: { treePosition, childIndex, completed },
});

export const setMarked = (
  treePosition: TreePositionStateType,
  childIndex: number,
  marked: boolean
) => ({
  type: SET_MARKED,
  payload: { treePosition, childIndex, marked },
});

export const moveChild = (
  treePosition: TreePositionStateType,
  sourceIndex: number,
  targetIndex: number
) => ({
  type: MOVE_CHILD,
  payload: { treePosition, sourceIndex, targetIndex },
});

export const moveChildInto = (
  treePosition: TreePositionStateType,
  sourceIndex: number,
  targetIndex: number
) => ({
  type: MOVE_CHILD_INTO,
  payload: { treePosition, sourceIndex, targetIndex },
});

export const moveChildUpTimes = (
  treePosition: TreePositionStateType,
  sourceIndex: number,
  times: number
) => ({
  type: MOVE_CHILD_UP_TIMES,
  payload: { treePosition, sourceIndex, times },
});

export const toggleEditTitle = (
  treePosition: TreePositionStateType,
  childIndex: number
) => ({
  type: TOGGLE_EDIT_TITLE,
  payload: { treePosition, childIndex },
});

export const toggleEditDescription = (
  treePosition: TreePositionStateType,
  childIndex: number
) => ({
  type: TOGGLE_EDIT_DESCRIPTION,
  payload: { treePosition, childIndex },
});
