import { includes } from '../helpers/commonHelper';
import { patchData } from '../actions/dataActions';
import {
  SAVABLE_ACTIONS,
} from '../constants/actionCollections';

const saveDataMiddleware = store => next => action => {
  next(action);

  const state = store.getState();

  if (
    state.user.isLoggedIn &&
    includes(SAVABLE_ACTIONS, action.type) &&
    !state.data.loadingSave
  )
    store.dispatch(patchData(
      state.data.lastUpdatedAt, // To tell which HEAD to patch upon
      state.data
    ));
};

export default saveDataMiddleware;
