import React, { useEffect, FC, useState, KeyboardEvent } from "react";
import Textarea from "react-textarea-autosize";

import { trim } from "../helpers/commonHelper";
import { useClickOutside } from "../helpers/useClickOutside";

interface PropsT {
  className?: string;
  defaultValue?: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
}

const EditableTextForm: FC<PropsT> = ({
  className,
  defaultValue,
  onSubmit,
  onCancel,
}) => {
  const [value, setValue] = useState<string>(defaultValue || "");

  const ref = useClickOutside<HTMLTextAreaElement>({
    onClickOutside: () => {
      onSubmit(trim(value));
    },
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.select();
    }
  }, [ref]);

  function onKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Escape") {
      e.preventDefault();
      onCancel();
    }
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(trim(value));
    }
  }

  return (
    <Textarea
      ref={ref}
      maxRows={5}
      className={className}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={onKeyDown}
    />
  );
};

export default EditableTextForm;
