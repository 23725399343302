export const MOVE_SAME_LEVEL = "MOVE_SAME_LEVEL";
export const MOVE_INTO = "MOVE_INTO";

export interface ChildMoveNodeTypes {
  MOVE_INTO: string;
  MOVE_SAME_LEVEL: string;
}

export const CHILD_MOVE_MODE_TYPES = {
  MOVE_INTO,
  MOVE_SAME_LEVEL,
};
