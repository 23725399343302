import React, { FC } from "react";

import Header from "../Header";
import Footer from "../Footer";

interface PropsT {
  location: Object;
}

const AppFrame: FC<PropsT> = (props) => (
  <div className="app">
    <Header location={props.location} />
    <div className="app-content">{props.children}</div>
    <Footer />
  </div>
);

export default AppFrame;
