// Action structure is compliant with FSA: https://github.com/acdlite/flux-standard-action
// in case the action returns an object
//
// In the second case, an action can be a function that is processed by redux-thunk
// to support asynchronous calls, e.g. API calls.

import * as genericActions from "./genericActions";
import * as loginActions from "./loginActions";
import * as dataActions from "./dataActions";
import * as navigateActions from "./navigateActions";
import * as modalActions from "./modalActions";
import * as childMoveModeActions from "./childMoveModeActions";

export default {
  ...genericActions,
  ...loginActions,
  ...dataActions,
  ...navigateActions,
  ...modalActions,
  ...childMoveModeActions,
};
