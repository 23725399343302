import React, { FC } from "react";
import classnames from "classnames";

import spinner from "../../images/spinner.svg";

interface PropsT {
  className?: string;
}

const LoadingSpinner: FC<PropsT> = ({ className }) => (
  <img
    className={classnames("loading-spinner", className)}
    alt="Loading"
    src={spinner}
  />
);

export default LoadingSpinner;
