import React, { ReactNode, FC } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FONT_MD } from "../constants/styles";

const SxIcon = styled(Icon)<{ large?: boolean }>`
  ${({ large }) =>
    large &&
    css`
      height: 1.5em;
    `}
`;

interface PropsT {
  title?: string;
  text?: ReactNode;
  className?: string;
  large?: boolean;
  white?: boolean;
  iconClassName?: IconName;
  onClick: () => void;
}

const Button = styled.button`
  cursor: pointer;
  transition: opacity 0.2s;
  font-size: ${FONT_MD};
`;
const Text = styled.span`
  padding-left: 5px;
`;

const IconButton: FC<PropsT> = (props) => (
  <Button
    title={props.title}
    onClick={props.onClick}
    className={props.className}
  >
    <SxIcon
      large={props.large}
      white={props.white}
      icon={props.iconClassName}
    />
    {props.text && <Text>{props.text}</Text>}
  </Button>
);

export default IconButton;
