import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const NodeLoading = () => (
  <div className="node-loading">
    <h2>Loading your data</h2>
    <LoadingSpinner className="node-loading__spinner" />
  </div>
);

export default NodeLoading;
