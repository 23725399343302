import { IS_PRODUCTION } from "./environment";

export const LANDING_ROUTE = "/";
export const APP_ROUTE = "/app";
export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/sign-up";
export const PROFILE_ROUTE = "/profile";
export const IMPRINT_ROUTE = "/imprint";
export const VERSION_ROUTE = "/version";
export const NOT_FOUND_ROUTE = "/not-found";

export const API_URL = IS_PRODUCTION
  ? "https://ampfive.com/api"
  : "http://localhost:8000";
