export const getParentNodes = (node, treePosition, parentNodes = []) => {
  if (!treePosition || !node || !node.children || treePosition.length === 0)
    return parentNodes;

  const pos = treePosition[0];
  const nextParentNodes = [
    ...parentNodes,
    {
      title: node.children[pos].title,
      description: node.children[pos].description,
    }
  ];

  return getParentNodes(node.children[pos], treePosition.slice(1), nextParentNodes);
};
