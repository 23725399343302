/* eslint-disable */
import React, { FC } from "react";
import { connect } from "react-redux";

import AppFrame from "./AppFrame";
import { StateType } from "../../reducers";

interface PropsT {
  user: Object;
  location: Object;
}

const Imprint: FC<PropsT> = (props) => (
  <AppFrame location={props.location}>
    <div className="imprint">
      <h2>Impressum</h2>
      <p>Kai-Adrian Rollmann</p>
      <p>Rollmann Software</p>
      <p>Eylauer Str 11, 10965 Berlin</p>
      <p>Telefon: 016094491310</p>
      <p> E-Mail: mail (at) kairollmann.de</p>
      <h2>Disclaimer – rechtliche Hinweise</h2>
      <h3>§ 1 Warnhinweis zu Inhalten</h3>
      <p>
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit
        größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt
        jedoch keine Gewähr für die Richtigkeit und Aktualität der
        bereitgestellten kostenlosen und frei zugänglichen journalistischen
        Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
        Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
        wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen
        Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem
        Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des
        Anbieters.
      </p>
      <h3>§ 2 Externe Links</h3>
      <p>
        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
        Links"). Diese Websites unterliegen der Haftung der jeweiligen
        Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen
        Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
        bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der
        Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige
        Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von
        externen Links bedeutet nicht, dass sich der Anbieter die hinter dem
        Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige
        Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise
        auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
      </p>

      <h3>§ 3 Urheber- und Leistungsschutzrechte</h3>
      <p>
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem
        deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber-
        und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
        vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
        Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
        Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
        von Inhalten in Datenbanken oder anderen elektronischen Medien und
        Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
        einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
        strafbar. Lediglich die Herstellung von Kopien und Downloads für den
        persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. Die
        Darstellung dieser Website in fremden Frames ist nur mit schriftlicher
        Erlaubnis zulässig.
      </p>

      <h3>§ 4 Besondere Nutzungsbedingungen</h3>
      <p>
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
        den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
        ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
        Einzelfall die besonderen Nutzungsbedingungen.Quelle:{" "}
        <a href="http://www.impressum-recht.de/impressum-generator/">
          Impressum-Generator von Impressum-recht.de
        </a>
      </p>

      <h2>Datenschutzerklärung</h2>
      <h3>Datenschutz</h3>
      <p>
        Nachfolgend möchten wir Sie über unsere Datenschutzerklärung
        informieren. Sie finden hier Informationen über die Erhebung und
        Verwendung persönlicher Daten bei der Nutzung unserer Webseite. Wir
        beachten dabei das für Deutschland geltende Datenschutzrecht. Sie können
        diese Erklärung jederzeit auf unserer Webseite abrufen. Wir weisen
        ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht
        lückenlos vor dem Zugriff durch Dritte geschützt werden kann. Die
        Verwendung der Kontaktdaten unseres Impressums zur gewerblichen Werbung
        ist ausdrücklich nicht erwünscht, es sei denn wir hatten zuvor unsere
        schriftliche Einwilligung erteilt oder es besteht bereits eine
        Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten
        Personen widersprechen hiermit jeder kommerziellen Verwendung und
        Weitergabe ihrer Daten.
      </p>
      <h3>Personenbezogene Daten</h3>
      <p>
        Sie können unsere Webseite ohne Angabe personenbezogener Daten besuchen.
        Soweit auf unseren Seiten personenbezogene Daten (wie Name, Anschrift
        oder E-Mail Adresse) erhoben werden, erfolgt dies, soweit möglich, auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben. Sofern zwischen Ihnen und uns
        ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert
        werden soll oder Sie an uns eine Anfrage stellen, erheben und verwenden
        wir personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken
        erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und nutzen
        personenbezogene Daten soweit dies erforderlich ist, um Ihnen die
        Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten).
        Sämtliche personenbezogenen Daten werden nur solange gespeichert wie
        dies für den geannten Zweck (Bearbeitung Ihrer Anfrage oder Abwicklung
        eines Vertrags) erforderlich ist. Hierbei werden steuer- und
        handelsrechtliche Aufbewahrungsfristen berücksichtigt. Auf Anordnung der
        zuständigen Stellen dürfen wir im Einzelfall Auskunft über diese Daten
        (Bestandsdaten) erteilen, soweit dies für Zwecke der Strafverfolgung,
        zur Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der
        Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes oder
        zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.
      </p>
      <h3>Datenschutzerklärung für den Webanalysedienst Google Analytics</h3>
      <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der
        Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies",
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
        Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
        in der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert. Wir haben die IP-Anonymisierung aktiviert. Auf dieser
        Webseite wird Ihre IP-Adresse von Google daher innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
        Website wird Google diese Informationen benutzen, um Ihre Nutzung der
        Website auszuwerten, um Reports über die Websiteaktivitäten
        zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem
        Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt. Sie können die Speicherung der Cookies durch
        eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir
        weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
        nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
        können. Sie können darüber hinaus die Erfassung der durch das Cookie
        erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:{" "}
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="nofollow">
          http://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>
      <h3>Auskunftsrecht</h3>
      <p>
        Sie haben das jederzeitige Recht, sich unentgeltlich und unverzüglich
        über die zu Ihrer Person erhobenen Daten zu erkundigen. Sie haben das
        jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben
        persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur
        Auskunftserteilung wenden Sie sich bitte an den Anbieter unter den
        Kontaktdaten im Impressum.
      </p>
      <p>
        Quelle:{" "}
        <a href="http://www.impressum-recht.de">www.impressum-recht.de</a>
      </p>
    </div>
  </AppFrame>
);

const mapStateToProps = (state: StateType) => ({ user: state.user });

export default connect(mapStateToProps)(Imprint);
