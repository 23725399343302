import React, { FC } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import T from "i18n-react";
import { SIGNUP_ROUTE } from "../../constants/routes";

import actions from "../../actions";

import AppFrame from "./AppFrame";
import LoginSignupForm from "../LoginSignupForm";

import { UserStateType } from "../../reducers/userReducer";

interface PropsT {
  location: Object;
  user: UserStateType;
  login: Function;
  closeModal: Function;
}

const Login: FC<PropsT> = (props) => (
  <AppFrame location={props.location}>
    <div className="login">
      <LoginSignupForm
        type="login"
        onSubmit={props.login}
        submitLabel={T.translate("Common.Login")}
        error={props.user.login.error}
        loading={props.user.login.loading}
        passwordSubtext={
          <Link className="login-signup-form__link" to={SIGNUP_ROUTE}>
            {T.translate("Login.DontHaveAnAccount")}
          </Link>
        }
      />
    </div>
  </AppFrame>
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    login: (email, password) => dispatch(actions.login(email, password)),
    closeModal: () => dispatch(actions.closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
