export const defaultError = (type: string, error: Object) => ({
  type,
  error: true,
  payload: error,
});

export const defaultSuccess = (type: string, results: Object) => ({
  type,
  payload: {
    data: results,
    receivedAt: Date.now(),
  },
});
