function trackNodeStats(node, depth = 0) {
  if (!node || !node.children || node.children.length === 0)
    return {
      depth,
      numNodes: 0,
    };

  return node.children.reduce(
    (a, b) => {
      const childStats = trackNodeStats(b, depth + 1);

      return {
        depth: Math.max(a.depth, childStats.depth),
        numNodes: a.numNodes + childStats.numNodes + 1, // + 1 parent node
      };
    },
    { depth: 0, numNodes: 0 }
  );
}

export const trackTreeStats = (node) => {
  const { numNodes, depth } = trackNodeStats(node);

  // Side-effect: Track
  (window as any).ga(
    "send",
    "event",
    "stats",
    "nodes-depth",
    `${numNodes}-${depth}`
  );
};
