import React, { FC } from "react";
import { connect } from "react-redux";

import LoadingSpinner from "./LoadingSpinner";
import Icon from "./Icon";
import styled from "styled-components";
import { COL_GREEN } from "../constants/styles";

const SxIcon = styled(Icon)`
  color: ${COL_GREEN};
`;

interface PropsT {
  isLoading: boolean;
  error: string;
}

const DataSync: FC<PropsT> = (props) => (
  <span className="data-sync">
    {props.error && <Icon icon="times" title="Could not sync" />}
    {props.isLoading ? (
      <LoadingSpinner className="data-sync__loading" />
    ) : (
      <SxIcon icon="check" title="Data sync successful" />
    )}
  </span>
);

function mapStateToProps(state) {
  return {
    isLoading: !!(state.data.loading || state.data.loadingSave),
    error: state.data.error,
  };
}

export default connect(mapStateToProps)(DataSync);
