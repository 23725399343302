import { APP_ROUTE } from "../constants/routes";

import { removeTrailingSlash } from "./commonHelper";

import { CardType } from "../reducers/dataReducer";

const slugify = (text?: string): string =>
  text
    ? text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, "") // Trim - from end of text
    : "";

export const isAppRoute = (pathname: string): boolean =>
  removeTrailingSlash(pathname) === APP_ROUTE;

export const getSlugsFromPath = (pathname: string): string[] => {
  const charsToRemoveFromPrefix: number = APP_ROUTE.length + 1;
  const slugsOnlyPath = removeTrailingSlash(
    pathname.slice(charsToRemoveFromPrefix)
  );

  return slugsOnlyPath.split("/");
};

export const getSlugsFromCards = (children: CardType[]) => {
  const sluggedTitles = children.map((child) => slugify(child.title));

  // Count duplicates
  const titleCountMap = sluggedTitles.reduce((titleMap, title) => {
    if (Object.keys(titleMap).indexOf(title) === -1) titleMap[title] = 1;
    else titleMap[title] += 1;

    return titleMap;
  }, {});

  // Same map again, the original one changes, this one stays for count reference
  const titleCountMapCopy = {
    ...titleCountMap,
  };

  return sluggedTitles.map((title) => {
    // Make the counts work
    if (titleCountMapCopy[title] > 1) {
      titleCountMap[title] -= 1;

      const iThTitle = titleCountMapCopy[title] - titleCountMap[title];

      return `${title}-${iThTitle}`;
    }

    return title;
  });
};

// Seems unnecessarily complicated, but we need to know all titles /
// all slugs to compute the slug of a single title (same titles may exist)
// Potentially, they could be kept in the state
export const getSlugFromCards = (children: CardType[], childIdx: number) =>
  getSlugsFromCards(children)[childIdx];
