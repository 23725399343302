import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";

import actions from "../actions";
import { getActiveNode } from "../selectors/activeNodeSelector";

import App from "../components/pages/App";
import { StateType } from "../reducers";
import { DataStateType, CardType } from "../reducers/dataReducer";
import { TreePositionStateType } from "../reducers/treePositionReducer";

function getHasError(state: StateType) {
  return !!state.data.errorGet || !!state.data.errorSave;
}

function getIsRootNode(treePosition: TreePositionStateType) {
  return !!treePosition && treePosition.length === 0;
}

interface PropsT {
  location: any;
}

const AppContainer: FC<PropsT> = ({ location }) => {
  const { pathname } = location;

  const data = useSelector<StateType, DataStateType>((state) => state.data);
  const treePosition = useSelector<StateType, TreePositionStateType>(
    (state) => state.treePosition
  );
  const activeNode = useSelector<StateType, DataStateType | CardType | null>(
    (state) => getActiveNode(state)
  );
  const isRootNode = useSelector<StateType, boolean>((state) =>
    getIsRootNode(state.treePosition)
  );
  const isLoadingGet = useSelector<StateType, boolean>(
    (state) => state.data.loadingGet
  );
  const isLoadingSave = useSelector<StateType, boolean>(
    (state) => state.data.loadingSave
  );
  const isLoggedIn = useSelector<StateType, boolean>(
    (state) => state.user.isLoggedIn
  );
  const hasError = useSelector<StateType, boolean>((state) =>
    getHasError(state)
  );

  const dispatch = useDispatch();

  const getData = () => dispatch(actions.getData({ pathname, init: true }));
  const undo = () => dispatch(actions.undo());
  // And now the ones that get overridden using the state's treePosition
  const navigateInto = (children: CardType[], childIndex: number) =>
    dispatch(actions.navigateInto(pathname, children, childIndex));
  const navigateUpTimes = (times: number) =>
    dispatch(actions.navigateUpTimes(pathname, times));
  const setChildMoveMode = (mode: string) =>
    dispatch(actions.setChildMoveMode(mode));

  const addNode = () => dispatch(actions.addNode(treePosition));
  const addNodeBefore = () => dispatch(actions.addNodeBefore(treePosition));
  const moveChildInto = (sourceIndex: number, targetIndex: number) =>
    dispatch(actions.moveChildInto(treePosition, sourceIndex, targetIndex));
  const initTreePosition = () =>
    dispatch(actions.treePositionInit(data, pathname));

  return (
    <App
      location={location}
      activeNode={activeNode}
      isRootNode={isRootNode}
      isLoadingGet={isLoadingGet}
      isLoadingSave={isLoadingSave}
      isLoggedIn={isLoggedIn}
      hasError={hasError}
      getData={getData}
      undo={undo}
      addNode={addNode}
      addNodeBefore={addNodeBefore}
      moveChildInto={moveChildInto}
      navigateInto={navigateInto}
      navigateUpTimes={navigateUpTimes}
      initTreePosition={initTreePosition}
      setChildMoveMode={setChildMoveMode}
    />
  );
};

export default AppContainer;
