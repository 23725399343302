import { CardType, DataStateType } from "../reducers/dataReducer";

import { TreePositionStateType } from "../reducers/treePositionReducer";

import { StateType } from "../reducers";

const findActiveNode = (
  data: DataStateType | CardType,
  treePosition: TreePositionStateType
): DataStateType | CardType | null => {
  if (!treePosition || !data || !data.children) return null;

  if (treePosition.length === 0) return data;

  return findActiveNode(data.children[treePosition[0]], treePosition.slice(1));
};

export const getActiveNode = (
  state: StateType
): DataStateType | CardType | null => {
  const { data, treePosition } = state;

  return findActiveNode(data, treePosition);
};
