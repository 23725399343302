import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "../constants/routes";

type PropsType = {
  active: string;
};

const LoginSignupToggle = ({ active }: PropsType) => (
  <div className="login-signup-toggle">
    <Link
      to={LOGIN_ROUTE}
      className={classnames("login-signup-toggle__link", {
        "login-signup-toggle__link--active": active === "login",
      })}
    >
      Login
    </Link>
    <Link
      to={SIGNUP_ROUTE}
      className={classnames("login-signup-toggle__link", {
        "login-signup-toggle__link--active": active === "signup",
      })}
    >
      Sign up
    </Link>
  </div>
);

export default LoginSignupToggle;
