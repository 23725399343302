import combineReducers from "./undoReducer";
import data, { DataStateType } from "./dataReducer";
import user, { UserStateType } from "./userReducer";
import treePosition, { TreePositionStateType } from "./treePositionReducer";
import modal, { ModalStateType } from "./modalReducer";
import animateDirection, {
  AnimateDirectionStateT,
} from "./animateDirectionReducer";
import childMoveMode, { ChildMoveModeStateType } from "./childMoveModeReducer";

export interface StateType {
  data: DataStateType;
  user: UserStateType;
  treePosition: TreePositionStateType;
  modal: ModalStateType;
  childMoveMode: ChildMoveModeStateType;
  animateDirection: AnimateDirectionStateT;
}

export default combineReducers({
  data,
  user,
  treePosition,
  animateDirection,
  modal,
  childMoveMode,
});
