export function trim(s: string) {
  return s.replace(/^\s+|\s+$/g, "");
}

export function removeTrailingSlash(string: string) {
  if (!string) return string;

  return string[string.length - 1] === "/"
    ? string.slice(0, string.length - 1)
    : string;
}

export function includes(array: any[], element: any) {
  return array.indexOf(element) !== -1;
}
