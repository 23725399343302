import { useRef, useEffect, useCallback } from "react";

export const useClickOutside = <T extends HTMLElement>({
  onClickOutside,
}: {
  onClickOutside: () => void;
}) => {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Element)) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside, false);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return ref;
};
