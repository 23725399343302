import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas, far);

export const SxIcon = styled(
  ({ left, right, center, white, color, ...props }) => (
    <FontAwesomeIcon {...props} />
  )
)`
  padding-right: ${({ left }) => (left ? "10px" : "0")};
  padding-left: ${({ right }) => (right ? "10px" : "0")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  color: ${({ white, color }) => (color ? color : white ? "white" : "inherit")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "inherit")};
  width: initial !important;
  height: 1em;
`;

interface PropsT {
  icon: IconProp;
  regular?: boolean;
  className?: string;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  white?: boolean;
  color?: string;
  title?: string;
}

const Icon: React.FC<PropsT> = ({
  icon,
  regular,
  className,
  left,
  right,
  center,
  white,
  color,
  title,
}) => {
  return (
    <SxIcon
      left={left}
      right={right}
      center={center}
      color={color}
      white={white}
      regular={regular}
      title={title}
      className={`fa-fw ${className}`}
      icon={icon}
    />
  );
};

export default Icon;
