import React, { FC } from "react";
import { Link } from "react-router-dom";
import T from "i18n-react";

import AppFrame from "./AppFrame";

import { APP_ROUTE } from "../../constants/routes";
import Icon from "../Icon";

interface PropsT {
  location: Object;
}

const NotFound: FC<PropsT> = (props) => (
  <AppFrame location={props.location}>
    <div className="not-found">
      <h2>
        <Icon icon={["far", "meh"]} /> {T.translate("NotFound.Headline")}
      </h2>
      <p>{T.translate("NotFound.Sorry")}</p>
      <Link to={APP_ROUTE} className="not-found__btn btn btn--small btn--green">
        <Icon icon="home" /> {T.translate("NotFound.GoBack")}
      </Link>
    </div>
  </AppFrame>
);

export default NotFound;
