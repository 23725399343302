import React, { FC } from "react";
import IconButton from "../IconButton";

import { useClickOutside } from "../../helpers/useClickOutside";
import { useKeyPress } from "../../helpers/useKeyPress";

export interface ModalPropsT {
  includesCloseIcon: boolean;
  onCloseModal: () => void;
}

const Modal: FC<ModalPropsT> = ({
  children,
  includesCloseIcon,
  onCloseModal,
}) => {
  const ref = useClickOutside<HTMLDivElement>({
    onClickOutside: onCloseModal,
  });

  useKeyPress("Escape", onCloseModal);

  return (
    <div
      className="modal"
      ref={(instance) => {
        ref.current = instance;
        if (instance) {
          instance.focus();
        }
      }}
    >
      {includesCloseIcon && (
        <IconButton
          className="btn btn--square modal__icon-button"
          large
          iconClassName="times"
          onClick={onCloseModal}
        />
      )}
      {children}
    </div>
  );
};

export default Modal;
